import React, { useRef, useEffect } from 'react';
import { gapi } from 'gapi-script';
import SafeIframe from './components/SafeIframe';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import { AuthProvider } from './AuthContext.js';
import PerlinBackground from './Background/PerlinBackground';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ProductsPage from './components/ProductsPage';
import GalleryPage from './components/GalleryPage';
// import BenchmarksPage from './components/BenchmarksPage';
import SignupPage from './components/SignupPage/signupform';
import ForgotPass from './components/ForgotPassPage';
import LoginPage from './components/LoginPage';
import News from './components/NewsPage';
import GlyphReviewPage from './components/GlyphReviewPage';
import AudioVerseReviewPage from './components/AudioVerseReviewPage';
import VisionaryReviewPage from './components/VisionaryReviewPage';
import AccountPage from './components/AccountPage';
import ModelDetailPage from './components/ModelDetailPage';
import ImageModelDetailPage from './components/ImageModelDetailPage';
import DisplayArticlePage from './components/DisplayArticlePage';
import Terms from './components/Terms/Terms'
import Privacy from './components/Privacy/privacy'
import ResetPassword from './components/ResetPasswordPage';

// CSS importsx
import './index.css';

// About Page
import './components/AboutPage/AboutFortaify.css';
import './components/AboutPage/FortaifyMission.css';
import './components/AboutPage/FortaifyVision.css';

// Account Settings
import './components/AccountSettings/AccountInformation.css';
import './components/AccountSettings/AccountSideBar.css';
import './components/AccountSettings/ChangePassword.css'
import './components/AccountSettings/DeleteAccount.css'
import './components/AccountSettings/ManagePayment.css'
import './components/AccountSettings/Subscriptions.css'

// Display Article Page
import './components/DisplayArticlePage/DisplayArticle.css'
import './components/DisplayArticlePage/ArticleSkeletonLoader.css'

// Footer CSS
import './components/Footer/Footer.module.css'
import './components/Footer/modal.module.css'

// Forgot Password Page
import './components/ForgotPassPage/ForgotPass.css'

// Gallery Page
import './components/GalleryPage/AudioGallery.css'
import './components/GalleryPage/Gallery.css'
import './components/GalleryPage/Sidebar.css'

// Header
import './components/Header/Header.css'

// Home Page
import './components/HomePage/HeroSection.css'
import './components/HomePage/Questions.css'
import './components/HomePage/Teams.css'
import './components/HomePage/Welcome.css'

// Login Page
import './components/LoginPage/Login.css'
import './components/LoginPage/ResetPassword.css'

// Modal Sign in
import './components/ModalSignIn/ModalSignIn.css'

// Model detail
import './components/ModelDetail/ImageModelDetail.css'
import './components/ModelDetail/ModelDetail.css'

// News Page
import './components/NewsPage/News.css'
import './components/NewsPage/NewsSkeleton.css'

// Privacy
import './components/Privacy/privacy.css'

// Products Page
import './components/ProductsPage/Glyph.css'
import './components/ProductsPage/MoreIdeas.css'
import './components/ProductsPage/Products.css'
import './components/ProductsPage/visionary.css'

// Products Review Page
import './components/ProductsReviewPage/GlyphReview.css'
import './components/ProductsReviewPage/VisionaryReview.css'
import './components/ProductsReviewPage/AudioVerseReview.css'

// Sign up Page
import './components/SignupPage/signupform.css'

// Terms
import './components/Terms/Terms.css'

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;


// main function
function App() {   
  const appRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: (response) => {
          console.log(response); // Handle the callback with the token or user info
        },
      });
      window.google.accounts.id.prompt(); // Automatically shows the One Tap dialog if applicable
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AuthProvider> 
    <Router>
      <div className="app content" ref={appRef}>
        <PerlinBackground />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/models/:modelId" element={<ModelDetailPage />} />
          <Route path="/image-models-details/:ImageGen_id" element={<ImageModelDetailPage />} />
          <Route path="/article/:articleId" element={<DisplayArticlePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<SignupPage />} />
          <Route path="/reseting-password" element={<ResetPassword />} />
          <Route path="/forgot" element={<ForgotPass />} />
          <Route path="/GlyphReviews" element={<GlyphReviewPage />} />
          <Route path="/AudioVerseReviews" element={<AudioVerseReviewPage />} />
          <Route path="/VisionaryReviews" element={<VisionaryReviewPage />} />
          <Route path="/Account" element={<AccountPage />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Privacy" element={<Privacy />} />
        </Routes>
      </div>
    </Router> 
    </AuthProvider>
  );
}

export default App;
