import React, { useState, useEffect } from 'react';
import './AccountSideBar.css';

function AccountSideBar({ onFilterChange }) {
    const [selectedOption, setSelectedOption] = useState('Information');
    const [isOpen, setIsOpen] = useState(true);  // Set this to true to have the sidebar open by default

    useEffect(() => {
    }, [isOpen]);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        if (option === 'Subscriptions' || option === 'Payments') {
            return;
        }
        onFilterChange(option);
        setSelectedOption(option);
    };

    return (
        <div className={`account-sidebar-container ${isOpen ? 'open' : ''}`}>
            <button className="account-sidebar-toggle" onClick={toggleSidebar}>
                <i className="fas fa-bars"></i>
            </button>

            <aside className="account-sidebar">
                <ul className="account-sidebar-list">
                    <li>
                        <button 
                            className={selectedOption === 'Information' ? 'selected' : ''}
                            onClick={() => selectOption('Information')}
                        >
                            Account Information
                        </button>
                    </li>
                    <li>
                        <button 
                            className={selectedOption === 'Subscriptions' ? 'selected disabled' : 'disabled'}
                            onClick={() => selectOption('Subscriptions')}
                            disabled
                            data-title="Disabled until product release"
                        >
                            Subscriptions
                        </button>
                    </li>
                    <li>
                        <button 
                            className={selectedOption === 'Payments' ? 'selected disabled' : 'disabled'}
                            onClick={() => selectOption('Payments')}
                            disabled
                            data-title="Disabled until product release"
                        >
                            Manage Payment
                        </button>
                    </li>
                    <li>
                        <button 
                            className={selectedOption === 'ChangePassword' ? 'selected' : ''}
                            onClick={() => selectOption('ChangePassword')}
                        >
                            Change Password
                        </button>
                    </li>
                    <li>
                        <button 
                            className={selectedOption === 'DeleteAccount' ? 'selected' : ''}
                            onClick={() => selectOption('DeleteAccount')}
                        >
                            Delete Account
                        </button>
                    </li>
                </ul>
            </aside>
        </div>
    );
}

export default AccountSideBar;
