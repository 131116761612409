import React from 'react';
import { NavLink } from 'react-router-dom';
import './Welcome.css';

function Welcome() {
  return (
    <div className="App">
      <h1 className="slogan">Unleash the power of text transformation</h1>
      <div className="button-container">
        <NavLink to="/gallery" className="nav-link">
          <button className="join-us-button">Start Exploring</button>
        </NavLink>
      </div>
    </div>
  );
}

export default Welcome;
