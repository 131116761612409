import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import './Login.css'; 

function Login() {
  const navigate = useNavigate();
  const { logIn } = useAuth(); 
  const [loginCredential, setLoginCredential] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleGoogleLogin = async (response) => {
    try {
      const decoded = jwtDecode(response.credential);
      const userData = {
        email: decoded.email,
        first_name: decoded.given_name,
        last_name: decoded.family_name,
        google_id: decoded.sub,
      };

      const { data } = await axios.post(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/google-login`, userData);
      logIn(data.token, () => {
        navigate('/products'); // Ensure navigate is called only after logIn updates the state
      });
    } catch (error) {
      console.error('Google login failed:', error);
      setErrorMessage('Google login failed. Please try again.');
    }
  };
  
  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleLogin,
      });
      window.google.accounts.id.renderButton(
        document.getElementById('google-signin'),
        { theme: 'outline', size: 'large' }
      );
      window.google.accounts.id.prompt(); // Shows the One Tap dialog
    };
    
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    if (window.google) {
      initializeGoogleSignIn();
    } else {
      loadGoogleScript();
    }

    return () => {
      if (window.google) {
        window.google.accounts.id.cancel();
      }
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      login_credential: loginCredential,
      password: password,
    };
    axios.post(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/login`, userData)
      .then(response => {
        logIn(response.data.token, () => {
          navigate('/products');
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          setErrorMessage("Incorrect username or password");
        } else {
          setErrorMessage("An error occurred during login");
        }
      });
  };

  return (
    <div id="login-container">
      <section id="login-form" className="login-section">
        <div className="login-form-box">
          <div className="login-form-value">
            <form onSubmit={handleSubmit}>
              <h2>Login</h2>
              {errorMessage && (
                <div className="login-error-message-container">
                  <div className="login-error-message">{errorMessage}</div>
                </div>
              )}
              <div className="login-inputbox">
                <input
                  type="text"
                  required
                  value={loginCredential}
                  onChange={(e) => setLoginCredential(e.target.value)}
                />
                <label>Email or Username</label>
              </div>
              <div className="login-inputbox">
                <input
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label>Password</label>
              </div>
              <button className="button" type="submit">Log in</button>
              <div id="google-signin"></div> {/* Google Sign-In button */}
              <div className="login-forget">
                <div className="login-remember">
                  <label>
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    Remember Me
                  </label>
                </div>
                <div>
                  <a href="/forgot">Forget Password</a>
                </div>
              </div>
              <div className="login-register">
                <p>Don't have an account? <a href="/register">Register</a></p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
