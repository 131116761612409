import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {GoogleOAuthProvider} from '@react-oauth/google'
import reportWebVitals from './reportWebVitals';

// About Page
import './components/AboutPage/AboutFortaify.css';
import './components/AboutPage/FortaifyMission.css';
import './components/AboutPage/FortaifyVision.css';

// Account Settings
import './components/AccountSettings/AccountInformation.css';
import './components/AccountSettings/AccountSideBar.css';
import './components/AccountSettings/ChangePassword.css'
import './components/AccountSettings/DeleteAccount.css'
import './components/AccountSettings/ManagePayment.css'
import './components/AccountSettings/Subscriptions.css'

// Display Article Page
import './components/DisplayArticlePage/DisplayArticle.css'
import './components/DisplayArticlePage/ArticleSkeletonLoader.css'

// Footer CSS
import './components/Footer/Footer.module.css'
import './components/Footer/modal.module.css'

// Forgot Password Page
import './components/ForgotPassPage/ForgotPass.css'

// Gallery Page
import './components/GalleryPage/AudioGallery.css'
import './components/GalleryPage/Gallery.css'
import './components/GalleryPage/Sidebar.css'

// Header
import './components/Header/Header.css'

// Home Page
import './components/HomePage/HeroSection.css'
import './components/HomePage/Questions.css'
import './components/HomePage/Teams.css'
import './components/HomePage/Welcome.css'

// Login Page
import './components/LoginPage/Login.css'
import './components/LoginPage/ResetPassword.css'

// Modal Sign in
import './components/ModalSignIn/ModalSignIn.css'

// Model detail
import './components/ModelDetail/ImageModelDetail.css'
import './components/ModelDetail/ModelDetail.css'

// News Page
import './components/NewsPage/News.css'
import './components/NewsPage/NewsSkeleton.css'

// Privacy
import './components/Privacy/privacy.css'

// Products Page
import './components/ProductsPage/Glyph.css'
import './components/ProductsPage/MoreIdeas.css'
import './components/ProductsPage/Products.css'
import './components/ProductsPage/visionary.css'

// Products Review Page
import './components/ProductsReviewPage/GlyphReview.css'
import './components/ProductsReviewPage/VisionaryReview.css'
import './components/ProductsReviewPage/AudioVerseReview.css'

// Sign up Page
import './components/SignupPage/signupform.css'

// Terms
import './components/Terms/Terms.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
