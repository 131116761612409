import React, { useState } from 'react';
import AccountSideBar from './AccountSettings/AccountSideBar';
import AccountInformation from './AccountSettings/AccountInformation';
import Subscriptions from './AccountSettings/Subscriptions';
import ChangePassword from './AccountSettings/ChangePassword';
import ManagePayment from './AccountSettings/ManagePayment';
import DeleteAccount from './AccountSettings/DeleteAccount';

const AccountPage = () => {
  const [selectedOption, setSelectedOption] = useState('Information');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const renderComponent = () => {
    switch (selectedOption) {
      case 'Information': return <AccountInformation />;
      case 'Subscriptions': return <Subscriptions />;
      case 'ChangePassword': return <ChangePassword />;
      case 'Payments': return <ManagePayment />;
      case 'DeleteAccount': return <DeleteAccount />;
      default: return <AccountInformation />;
    }
  };

  return (
    <div className="account-page">
      <AccountSideBar onFilterChange={handleOptionChange} />
      <div className="account-content">
        {renderComponent()}
      </div>
    </div>
  );
}

export default AccountPage;
