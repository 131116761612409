import React, { useEffect } from 'react';
import AboutFortaify from './AboutPage/AboutFortaify';
import FortaifyMission from './AboutPage/FortaifyMission';
import FortaifyVision from './AboutPage/FortaifyVision';
import Footer from './Footer/Footer';

const AboutPage = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <div>
      <AboutFortaify />
      <FortaifyMission />
      <FortaifyVision />
      <Footer />
    </div>
  );
}

export default AboutPage;
