import React, { useEffect } from 'react';
import Footer from './Footer/Footer';
import AudioVerseReview from './ProductsReviewPage/AudioVerseReview';

const AudioVerseReviewPage = () => {

    // Scroll to top on component mount
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
    <div>
      <AudioVerseReview />
      <Footer />
    </div>
  );
}

export default AudioVerseReviewPage;