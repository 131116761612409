import React, { useEffect } from 'react';
import Footer from './Footer/Footer';
import Welcome from './HomePage/Welcome';
import HeroSection from './HomePage/HeroSection';
import Questions from './HomePage/Questions';
import Teams from './HomePage/Teams';

const HomePage = () => {  
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Welcome />
      <HeroSection />
      <Questions />
      <Teams />
      <Footer />
    </div>
  );
}

export default HomePage;