import React from 'react';
import { Link } from 'react-router-dom';
import './AboutFortaify.css';

function AboutFortaify() {
  return (
    <section className="about-fortaify">
      <h1>Making AI models and Privacy for everyone</h1>
      <p>Fortaify is an AI technology company specializing in text transformation products. We provide free use and subscriptions to open source AI/ML models for text to speech, text to sound effects, text to image, and text to text generation, ensuring privacy and AI models are accessible to everyone.</p>
      <Link to="/news" className="join-us-button">Read AI News</Link>
      <div className="white-bar"></div>
    </section>
  );
}

export default AboutFortaify;
