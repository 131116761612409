import React, { useState } from 'react';
import './ManagePayment.css'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ManagePayment() {

  // State for payment information
  const [paymentInfo, setPaymentInfo] = useState([
    { cardName: 'John Doe', cardNumber: '1234 5678 9012 3456', maskedCardNumber: '**** **** **** 3456', expiryDate: '12/23', cvv: '123', isVisible: false },
    { cardName: 'Jane Smith', cardNumber: '6543 2109 8765 4321', maskedCardNumber: '**** **** **** 4321', expiryDate: '01/25', cvv: '456', isVisible: false }
    // Add more payment cards as needed
  ]);

  // Function to toggle visibility of card number and CVV
  const toggleVisibility = (index) => {
    const newPaymentInfo = paymentInfo.map((card, i) => 
      i === index ? { ...card, isVisible: !card.isVisible } : card
    );
    setPaymentInfo(newPaymentInfo);
  };


  return (
    <div className="manage-payment-container">
      <div className="form-container">
        <h2>Add New Payment</h2>
        <div className="inputbox">
          <input type="text" name="cardName" required />
          <label>Cardholder's Name</label>
        </div>
        <div className="inputbox">
          <input type="text" name="cardNumber" required />
          <label>Card Number</label>
        </div>
        <div className="inputbox">
          <input type="text" name="expiryDate" required />
          <label>Expiry Date</label>
        </div>
        <div className="inputbox">
          <input type="text" name="cvv" required />
          <label>CVV</label>
        </div>
        <div className="inputbox">
            <input type="text" name="zipCode" required />
            <label>Zip Code</label>
        </div>
        <div className="inputbox">
        <select name="country" required>
            <option value="">Select Country</option>
            <option value="USA">USA</option>
            <option value="Canada">Canada</option>
            </select>
        </div>
        <button>Add Payment Method</button>
      </div>

      <div className="payment-methods-container">
        {paymentInfo.map((card, index) => (
          <div className="payment-card" key={index}>
            <p>Cardholder's Name: {card.cardName}</p>
            <p>Card Number: {card.isVisible ? card.cardNumber : card.maskedCardNumber}</p>
            <p>Expiry Date: {card.expiryDate}</p>
            <p>CVV: {card.isVisible ? card.cvv : '***'}</p>
            <button className="eye-button" onClick={() => toggleVisibility(index)}>
              {card.isVisible ? <FaEye /> : <FaEyeSlash />}
            </button>
            <button>Edit</button>
            <button>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManagePayment;
