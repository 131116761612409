import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AccountInformation.css';

// Configure axios to send cookies with each request if needed
axios.defaults.withCredentials = true;

// Set up axios interceptor for Authorization
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const AccountInformation = () => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error("Token is missing.");
          // Handle the case where the token is missing, e.g., redirect to login
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/get-user-info`);
        setUserInfo(response.data); // Set user info for display
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          // Handle unauthorized access, e.g., redirect to login
        }
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/update-user-info`, userInfo);
      alert('Information updated successfully!');
    } catch (error) {
      console.error('Error updating user information:', error);
      alert('Failed to update information.');
    }
  };

  return (
    <section className="account-information-section">
      <div className="user-info-section">
        <h2 className="account-title">Current User Information</h2>
        <p className="account-info">First Name: {userInfo.firstName}</p>
        <p className="account-info">Last Name: {userInfo.lastName}</p>
        <p className="account-info">Email Address: {userInfo.email}</p>
        <p className="account-info">Username: {userInfo.username}</p>
      </div>
      <form onSubmit={handleSubmit} className="account-form-box">
        <h2 className="account-title">Update Information</h2>
        {['firstName', 'lastName', 'email', 'username'].map(field => (
          <div className="account-inputbox" key={field}>
            <input
              type={field === 'email' ? 'email' : 'text'}
              name={field}
              required
              value={userInfo[field]}
              onChange={handleChange}
            />
            <label className="account-label">{`Change ${field}`}</label>
          </div>
        ))}
        <button type="submit" className="account-button">Update Information</button>
      </form>
    </section>
  );
};

export default AccountInformation;
