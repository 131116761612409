import React, { useState, useEffect } from 'react';
import './Gallery.css';
import './AudioGallery.css';

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

function ModelCard({ model, isLoading }) { 

  const handleDownloadClick = () => {
    window.open(model.DownloadLink, '_blank', 'noopener,noreferrer');
  };

  const formattedModelSize = model.ModelSize != null ? `${model.ModelSize} GB` : 'N/A';
  const formattedRAMUsage = model.RAMUsage != null ? `${model.RAMUsage} GB` : 'N/A';

  const handleMoreInfoClick = () => {
    window.location.href = `/models/${model.DownloadID}`; 
  };

  return (
    <div className="model-card">
      <h2>{model.QuantName}</h2>
      <div className="model-details">
        <span>Quant Method: {model.QuantMethod}</span>
        <span>Model Size: {formattedModelSize}</span>
        <span>RAM Usage: {formattedRAMUsage}</span>
      </div>
      <div className="button-container">
        <button onClick={handleDownloadClick} className="download-button">Download</button>
        <button onClick={handleMoreInfoClick} className="MoreInfo-button">More Info</button>
      </div>
    </div>
  );
}

function AudioGallery() {
  const [models, setModels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Correctly defined within the scope of ModelGallery
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentModels = models.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const loadModels = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/models');
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const modelsData = await response.json();
        setModels(modelsData);
        setTotalPages(Math.ceil(modelsData.length / itemsPerPage));
      } catch (error) {
        console.error("Error loading models:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadModels();
  }, []);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  
  return (
    <div className="models">
      <h1>Explore AI Models</h1>
      <div className="coming-soon-container">
      <h2>Coming Soon</h2>
    </div>

    </div>
  );
}

export default AudioGallery;
