import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate instead of Navigate
import './ResetPassword.css'; // Ensure this CSS file contains the necessary styles

function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); // Correctly getting the token from query params
    const [isValidToken, setIsValidToken] = useState(false);
  
    const [passwords, setPasswords] = useState({
      newPassword: '',
      confirmNewPassword: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setPasswords(prevState => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwords.newPassword !== passwords.confirmNewPassword) {
          setErrorMessage('New password and confirm password do not match.');
          return;
        }
        
        try {
          const formData = new URLSearchParams();
          formData.append('newPassword', passwords.newPassword);
    
          const response = await axios.post(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/update-password/${token}`, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
    
          if (response.data.message) {
            setErrorMessage(response.data.message);
          } else {
            setErrorMessage('Password changed successfully! Please login with your new password.');
            setPasswords({
              newPassword: '',
              confirmNewPassword: ''
            });
            navigate('/login'); // Example: redirect to login page
          }
        } catch (error) {
          console.error('Error changing password:', error);
          setErrorMessage('Failed to change password.');
        }
      };
          

      useEffect(() => {
        const validateToken = async () => {
            try {
                // Attempt to validate the token
                axios.get(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/validate-reset-token/${token}`)
                .then(response => {
                  if (response.data.valid) {
                    setIsValidToken(true);
                  } else {
                    // Handle the case where the token is invalid or used
                    setIsValidToken(false);
                    setErrorMessage(response.data.message || 'Invalid or expired reset link.');
                  }
                })
                .catch(error => {
                  console.error('Error validating token:', error.response?.data?.message || 'An error occurred.');
                  setIsValidToken(false);
                  // Use the error message from the response if available, otherwise a default message
                  setErrorMessage(error.response?.data?.message || 'Error validating reset link.');
                });
            } catch (error) {
                console.error('Error validating token:', error);
                setIsValidToken(false);
                setErrorMessage('Error validating reset link.');
            }
        };
    
        if (token) {
            validateToken();
        }
    }, [token]);
    
  return (
    <section className="change-password-section">
      <div className="form-box">
        <h2>Change Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputbox">
            <input
              type="password"
              name="newPassword"
              required
              value={passwords.newPassword}
              onChange={handleChange}
            />
            <label>New Password</label>
          </div>
          <div className="inputbox">
            <input
              type="password"
              name="confirmNewPassword"
              required
              value={passwords.confirmNewPassword}
              onChange={handleChange}
            />
            <label>Confirm New Password</label>
          </div>
          <button type="submit" className="update-password-button">
            Change Password
          </button>
          <p>Note: If you have are logged in with a google account your account is managed by google auth and does not have a password</p>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
    </section>
  );
}

export default ResetPassword;
