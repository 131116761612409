import React, { useEffect } from 'react';
import ProductInfo from './ProductsPage/Products';
import Glyph from './ProductsPage/Glyph';
import Visionary from './ProductsPage/visionary';
import AudioVerse from './ProductsPage/audioverse';
import Footer from './Footer/Footer';
import MoreIdeas from './ProductsPage/MoreIdeas';

const ProductsPage = () => {
    // Scroll to top on component mount
    useEffect(() => {
      window.scrollTo(0, 0);
      document.body.style.overflowX = 'hidden';
    }, []);

  return (
    <div>
      <ProductInfo />
      <Glyph />
      <Visionary />
      <AudioVerse />
      <MoreIdeas />
      <Footer />
    </div>
  );
}

export default ProductsPage;