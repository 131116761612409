import React from 'react';
import ForgotPass from './ForgotPassPage/ForgotPass';

const ForgotPassPage = () => {
  return (
    <div>
      <ForgotPass />
    </div>
  );
}

export default ForgotPassPage;