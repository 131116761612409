import React, { useState } from 'react';
import './Questions.css';

function Questions() {
  const faqs = [
    { 
      question: "What is Fortaify?", 
      answer: "Fortaify is an innovative technology company, revolutionizing the way we interact with computers. It provides tools that can convert text into diverse media formats, such as vivid images, dynamic audio, and enhanced text, catering to both personal and professional needs." 
    },
    { 
      question: "What data does Fortaify collect?", 
      answer: "Fortaify will only collect the data that is needed for account creation and subscription tracking. all chats, images, and audio generated are stored locally on your deviceand fortaify has no access to your data." 
    },
    { 
      question: "Is there a trial version available for Fortaify products?", 
      answer: "Certainly! We provide trial versions for all Fortaify products, allowing you to fully explore their potential and see how they fit into your workflow before committing to a subscription. Try out everything for free for 3 days." 
    },
    { 
      question: "Is there a community or forum where I can share ideas or get help with Fortaify's products?", 
      answer: "Yes! Check out all of Fortaify's discord and other social media in the footer below. We're always looking for ways to improve our products and share your ideas with the community." 
    },
    { 
      question: "How do I report a problem or bug with one of Fortaify's products?", 
      answer: "If you come accross a problem or bug, please report it through our discord server or directly via email to support@fortaify.com. Provide as much detail as possible to help us understand and address the issue promptly." 
    },
    { 
      question: "What Hardware do I need to run these applications?", 
      answer: "It is recommended that you have a gpu with at least 8gb of vram for these applications. However, for those without a gpu or a M1 or better Macbook you will still be able to use all of our products. Preformance will just be degraded." 
    }
  ];
  

  // Instead of a single active index, use a state that tracks the active state for each FAQ
  const [activeFAQs, setActiveFAQs] = useState({});

  // Function to toggle the active state of an FAQ item
  const toggleFAQ = index => {
    setActiveFAQs(prevActiveFAQs => ({
      ...prevActiveFAQs,
      [index]: !prevActiveFAQs[index]
    }));
  };


  return (
    <section className="faq-section">
      <h1>FAQs</h1>
      <div className="faq-grid">
        {faqs.map((faq, index) => (
          <div
            className={`faq-item ${activeFAQs[index] ? 'active' : ''}`}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Questions;
