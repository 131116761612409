import React from 'react';
import ModelDetail from './ModelDetail/ModelDetail';
import { useParams } from 'react-router-dom';

function ModelDetailPage() {
    const { modelId } = useParams();

    return (
        <div>
            <ModelDetail modelId={modelId} />
        </div>
    );
}

export default ModelDetailPage;
