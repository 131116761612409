import React, { useEffect, useState } from 'react';
import './ForgotPass.css'; 
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of Navigate

function ForgotPass() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleSubmit = (event) => {
    event.preventDefault();
  
    fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      navigate('/login'); // Use navigate to redirect after successful API call
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  
  return (
    <div className="forgot-pass-container">
      <section className="forgot-pass-section">
        <div className="forgot-pass-form-box">
          <form onSubmit={handleSubmit} className="forgot-pass-form">
            <h2 className="forgot-pass-title">Reset Your Password</h2>
            <div className="forgot-pass-inputbox">
              <input 
                type="email" 
                required 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
              />
              <label htmlFor="email">Enter your email address</label>
            </div>
            <button type="submit" className="forgot-pass-button">Send Reset Link</button>
          </form>
        </div>
        <div className="white-circle">
          <a href="/login">
            <i className="fas fa-user"></i>
          </a>
        </div>
      </section>
    </div>
  );
}

export default ForgotPass;
