import React, { useState } from 'react';
import './HeroSection.css';
  
import glyphCardImage from '../../img/appImages/GlyphSS.png';
import audioverseCardImage from '../../img/HeroSection/AudioVerseImage.png';
import visionaryCardImage from '../../img/appImages/VisionarySS.png';

function ProductDisplay({ title, description, imageSrc }) {
  const [hover, setHover] = useState(false);

  const hoverStyles = hover ? { color: 'blue' } : null; // Simplified conditional style
  return (
    <div 
      className="product-display" 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={imageSrc} alt={title} />
      <h1 style={hoverStyles}>{title}</h1>
      <p>{description}</p>
    </div>
  );
}

function HeroSection() {
  return (
    <section className="hero">
      <div className="row">
        <ProductDisplay 
          title="Glyph" 
          description="Get answers and chat with AI bots" 
          imageSrc={glyphCardImage} 
        />
        <ProductDisplay 
          title="Audioverse" 
          description="Transform text to lifelike audio" 
          imageSrc={audioverseCardImage} 
        />
        <ProductDisplay 
          title="Visionary" 
          description="Create stunning visuals from text" 
          imageSrc={visionaryCardImage} 
        />
      </div>
    </section>
  );
}

export default HeroSection;
