import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './signupform.css'; 

// Modal Component
function Modal({ isOpen, message, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <p>{message}</p>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
}

// SignupForm Component
function SignupForm() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    const preventScroll = (event) => event.preventDefault();
    window.addEventListener('mousewheel', preventScroll, { passive: false });
    window.addEventListener('DOMMouseScroll', preventScroll, { passive: false });

    return () => {
      window.removeEventListener('mousewheel', preventScroll);
      window.removeEventListener('DOMMouseScroll', preventScroll);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setModalMessage("Passwords do not match!");
      setShowModal(true);
      return;
    }

    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      username: username,
      password: password,
      is_google_account: isGoogleAccount,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (response.ok) {
        setModalMessage('User registered successfully! Please confirm your email by pressing the link sent to your email.');
        setErrorOccurred(false);
        setShowModal(true);
        setTimeout(() => {
          navigate('/login');
        }, 10000);
      } else {
        setModalMessage('Registration error: ' + (data.message || 'Unknown Error'));
        setErrorOccurred(true);
        setShowModal(true);
      }
    } catch (error) {
      setModalMessage('Network error: ' + error.message);
      setErrorOccurred(true);
      setShowModal(true);
    }
  };

  return (
    <div id="signup-container">
      <section id="signup-section">
        <div className="signup-form-box">
          <div className="signup-form-content">
            <form onSubmit={handleSubmit} className="signup-form">
              <h2 className="signup-title">Sign Up</h2>
              <div className="name-fields">
                <div className="signup-inputbox name-field">
                  <input 
                    type="text" 
                    required
                    value={firstName} 
                    onChange={(e) => setFirstName(e.target.value)} 
                  />
                  <label>First Name</label>
                </div>
                <div className="signup-inputbox name-field">
                  <input 
                    type="text" 
                    required
                    value={lastName} 
                    onChange={(e) => setLastName(e.target.value)} 
                  />
                  <label>Last Name</label>
                </div>
              </div>
              <div className="signup-inputbox full-width">
                <input 
                    type="email" 
                    required
                    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    title="Please enter a valid email address with a domain (e.g., .com, .net)."
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
                <label>Email</label>
                </div>
                <div className="signup-inputbox">
                  <input 
                    type="text" 
                    required
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                  />
                  <label>Username</label>
                </div>
                <div className="signup-inputbox">
                  <input 
                    type="password" 
                    required
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                  />
                  <label>Password</label>
                </div>
                <div className="signup-inputbox">
                  <input 
                    type="password" 
                    required
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                  />
                  <label>Confirm Password</label>
                </div>
                <button type="submit" className="signup-button">Sign Up</button>
                </form>
          </div>
        </div>
        <div className="white-circle">
          <a href="/login">
            <i className="fas fa-user"></i>
          </a>
        </div>
      </section>

      <Modal isOpen={showModal} message={modalMessage} onClose={() => setShowModal(false)} />
    </div>
  );
}

export default SignupForm;
