import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);  // Add loading state

  const checkSession = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoggedIn(false);
        setLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/verifySession`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        withCredentials: true
      });

      if (response.status === 200) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Error verifying session:', error);
      setIsLoggedIn(false);
    } finally {
      setLoading(false);  // Ensure loading state is set to false
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  const logIn = (token, callback) => {
    localStorage.setItem('token', token); // Save token to localStorage
    setIsLoggedIn(true);
    if (callback) {
      callback(); // Call the callback after updating the state
    }
  };

  const logOut = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, logIn, logOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
