import React, { useState } from 'react';
import './MoreIdeas.css';

// This function has been isolated for modularity.
export const postFeatureRequest = async (formData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/featureRequests`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};

function MoreIdeas() {
  const [formData, setFormData] = useState({ product: 'Glyph', featureRequest: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (isSubmitted) setIsSubmitted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postFeatureRequest(formData);
      console.log('Response:', response);
      setFormData({ ...formData, featureRequest: '' });
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting feature request:', error);
    }
  };

  return (
    <div className="product-page">
      <div className="feature-request">
        <h1 className="about-products">Missing a feature you would like?</h1>
        <h1 className="about-products">Let us know!</h1>
        {isSubmitted && <div className="thank-you-message">Thank you for your request!</div>}
      </div>

      <form className="feature-request-form" onSubmit={handleSubmit}>
        <select name="product" value={formData.product} onChange={handleChange}>
          <option value="Glyph">Glyph</option>
          <option value="Visionary">Visionary</option>
          <option value="Audioverse">Audioverse</option>
          <option value="Fortaify">Fortaify Website</option>
        </select>
        <textarea
          name="featureRequest"
          value={formData.featureRequest}
          onChange={handleChange}
          placeholder="Describe your feature request"
          required
        ></textarea>
        <button type="submit">Submit Request</button>
      </form>
    </div>
  );
}

export default MoreIdeas;
