import React from 'react';
import { Link } from 'react-router-dom';
import './ModalSignIn.css';

function ModalSignIn({ onClose }) {
  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h2>Sign In Required</h2>
        <p>You need to sign in to like, dislike, and comment on articles.</p>
        {/* Apply button styling to the Link component */}
        <Link to="/login" className="buttonStyle">Sign In</Link>
        <button onClick={onClose} className="buttonStyle">Close</button>
      </div>
    </div>
  );
}

export default ModalSignIn;
