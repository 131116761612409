import React, { useState, useEffect } from 'react';
import './ModelDetail.css';
import ModalSignIn from '../ModalSignIn/ModalSignIn';

function ModelDetail({ modelId }) {
    const [model, setModel] = useState(null);
    const [quantizedModels, setQuantizedModels] = useState([]);
    const [comments, setComments] = useState([]);
    const [reviewText, setReviewText] = useState('');
    const [starReview, setStarReview] = useState(0);

    const [downloads, setDownloads] = useState(0);
    const [isHeartBeating, setIsHeartBeating] = useState(false);
    const [isDislikeHeartBeating, setIsDislikeHeartBeating] = useState(false);
    const [isDownloadIconActive, setIsDownloadIconActive] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);

    // Existing states and hooks
    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);
    // Define new states for tracking user's like and dislike status
    const [userLiked, setUserLiked] = useState(false);
    const [userDisliked, setUserDisliked] = useState(false);



    useEffect(() => {
        fetchTextModel();
        fetchTextModelReviews(); // Fetch reviews in a separate call
        document.body.style.overflow = 'auto';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [modelId]);

    const handleCloseModal = () => {
        setShowSignInModal(false);
    };

    const fetchTextModelReviews = async () => {
        try {
            const reviewsResponse = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/text-models-reviews/${modelId}/read`);
            if (!reviewsResponse.ok) {
                throw new Error('Network response was not ok for reviews');
            }
            const reviewsData = await reviewsResponse.json();
            const sortedComments = reviewsData.sort((a, b) => new Date(b.comment_date) - new Date(a.comment_date));
    
            setComments(sortedComments);
        } catch (error) {
            console.error('Fetch reviews error:', error);
        }
    };
    
    const fetchTextModel = async () => {
        try {
            const modelResponse = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/models/${modelId}`);
            if (!modelResponse.ok) {
                throw new Error('Network response was not ok');
            }
            const modelData = await modelResponse.json();
            setModel(modelData);

            const quantizedModelsResponse = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/models/${modelId}/quantized`);
            if (!quantizedModelsResponse.ok) {
                throw new Error('Network response was not ok for quantized models');
            }
            const quantizedModelsData = await quantizedModelsResponse.json();
            setQuantizedModels(quantizedModelsData);

            setLikes(modelData.likes || 0);
            setDislikes(modelData.dislikes || 0);
            setDownloads(modelData.num_downloads || 0);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };


    const renderQuantizedModelsList = () => {
        return quantizedModels.length > 0 ? (
            <div className="quantized-models-list">
                {quantizedModels.map((qm, index) => (
                    <div key={qm.id || index} className="quantized-model-detail">
                        <p><strong>Quantization Name:</strong> {qm.quant_name}</p>
                        <p><strong>Quantization Method:</strong> {qm.quant_method}</p>
                        <p><strong>Download Link:</strong> 
                            <a href={qm.download_link} onClick={handleDownloadClick}>Download</a>
                        </p>
                        <p><strong>Model Size:</strong> {qm.model_size} GB</p>
                        <p><strong>RAM Usage:</strong> {qm.ram_usage} GB</p>
                    </div>
                ))}
            </div>
        ) : null;
    };
        

    if (!model) {
        return <div className='loading-model-detail'>Loading model details...</div>;
    }


    const handleLikeClick = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/text-models-likes/${modelId}`, {
                method: 'POST',
                credentials: 'include',
            });

            if (response.status === 401) {
                setShowSignInModal(true);
                return; // Early return if user is not authenticated
            }

            // Assume user can't both like and dislike simultaneously
            if (userDisliked) {
                setDislikes(dislikes - 1); // Decrease dislikes if previously disliked
                setUserDisliked(false);    // Update state to reflect the change
            }
            setIsHeartBeating(true);
            setTimeout(() => setIsHeartBeating(false), 500);
        
            const newLikes = userLiked ? likes - 1 : likes + 1; // Toggle like
            setLikes(newLikes); // Optimistically update likes
            setUserLiked(!userLiked); // Toggle userLiked state
        

    
            const data = await response.json();
            setLikes(data.likes); // Update likes from response
            setUserLiked(data.action === "liked");
            setUserDisliked(false); // Ensure userDisliked is false if like was successful
        } catch (error) {
            console.error('Error updating likes:', error);
            // Rollback optimistic updates in case of error
            setLikes(userLiked ? likes + 1 : likes - 1);
            setUserLiked(!userLiked); // Revert state change
        }
    };
    
    const handleDislikeClick = async () => {
    
        try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/text-models-dislikes/${modelId}`, {
                method: 'POST',
                credentials: 'include',
            });

            if (response.status === 401) {
                setShowSignInModal(true);
                return; // Early return if user is not authenticated
            }

            // If the model was previously liked, decrement likes and update state
            if (userLiked) {
                setLikes(likes - 1);
                setUserLiked(false);
            }
        
            setIsDislikeHeartBeating(true);
            setTimeout(() => setIsDislikeHeartBeating(false), 500);
        
            // Toggle dislike based on previous state
            const newDislikes = userDisliked ? dislikes - 1 : dislikes + 1;
            setDislikes(newDislikes); // Optimistically update dislikes
            setUserDisliked(!userDisliked); // Toggle userDisliked state

    
            const data = await response.json();
            
            // Server might return the updated dislikes count and action taken
            setDislikes(data.dislikes); // Update dislikes from response
            setUserDisliked(data.action === "disliked");
            setUserLiked(false); // Ensure userLiked is false if dislike was successful
        } catch (error) {
            console.error('Error updating dislikes:', error);
            // Rollback optimistic updates in case of error
            setDislikes(userDisliked ? dislikes + 1 : dislikes - 1);
            setUserDisliked(!userDisliked); // Revert state change
        }
    };
    
    const handleDownloadClick = async () => {
        setIsDownloadIconActive(true);
        setTimeout(() => {
            setIsDownloadIconActive(false);
        }, 500);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/models/${modelId}/increment-download`, {
                method: 'POST',
                credentials: 'include', // If your API requires authentication
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const updatedDownloads = await response.json();
            setDownloads(updatedDownloads.downloads); // Update the state with the new downloads count
        } catch (error) {
            console.error('Failed to increment download count:', error);
        }
    };
    
    const submitReview = async () => {
        const review = {
            Rating: starReview,
            CommentText: reviewText,
        };
        
        try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/text-models-reviews/${modelId}/write`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(review),
            });
    
            if (response.status === 401) {
                setShowSignInModal(true);
                return; 
            }

            if (response.status === 400) {
                return;
            }
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Reset review form upon successful submission
            setReviewText('');
            setStarReview(0);
            
            // Refresh comments to include the new one
            await fetchTextModelReviews();
        } catch (error) {
            console.error('Submit review error:', error.message);
            alert(error.message); // Display a generic error message to the user
        }
    };
                
    const renderReviews = () => {
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        };
    
        return comments.length > 0 ? (
            <div className="review-card-area">
                <div className="text-reviews-card-list">
                    {comments.map((review) => (
                        <div key={review.CommentID} className="review-card">
                            <div className="text-review-rating-cards">
                                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                            </div>
                            <div className="text-review-text">{review.comment_text}</div>
                            <div className="text-review-username">By: {review.username}</div>
                            <div className="text-review-timestamp">Date: {formatDate(review.comment_date)}</div>
                        </div>
                    ))}
                </div>
            </div>
        ) : (
            <p className="no-text-reviews">No reviews yet. Be the first?</p>
        );
    };

    const handleStarReview = (review) => {
        setStarReview(review);
    };

    const renderInfo = (label, data) => {
        return <p><strong>{label}:</strong> {data}</p>;
    };

    const renderStarReview = () => {
        return (
            <div>
                {[1, 2, 3, 4, 5].map((star) => (
                    <span key={star} onClick={() => handleStarReview(star)}>
                        {star <= starReview ? '★' : '☆'}
                    </span>
                ))}
            </div>
        );
    };

    if (!model) {
        return <div className='loading-model-detail'>Loading model details...</div>;
    }

    const link = model.model_link || 'N/A';

    return (
        <div className="model-detail-container">
            <div className="model-detail-header model-detail-card">
                <h2>{model.model_name || 'N/A'}</h2>
                {renderInfo('Author Name', model.author_name || 'N/A')}
            </div>

            <div className="card-model-description">
                <div className="description-content">
                {renderInfo('Model Description ')}
                    {model.model_description || 'N/A'}
                </div>
            </div>

            
            <div className="card-downloads">
                {renderInfo('Downloads', downloads)}
                {renderQuantizedModelsList()}
            </div>

            <div className="card-likes model-detail-card" onClick={handleLikeClick}>
                {renderInfo('Likes', likes)}
                <div className="like-icon-wrapper">
                    <i className={`fa-solid fa-heart like-icon ${isHeartBeating ? 'beat' : ''}`}></i>
                </div>
            </div>
            <div className="card-dislikes model-detail-card">
                {renderInfo('Dislikes', dislikes)}
                <div className="dislike-icon-wrapper" onClick={handleDislikeClick}>
                    <i className={`fa-solid fa-heart-broken dislike-icon ${isDislikeHeartBeating ? 'beat' : ''}`}></i>
                </div>
            </div>

            <div className="card-huggingface model-detail-card">
                {renderInfo('View on Hugging Face')}
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src="https://huggingface.co/front/assets/huggingface_logo-noborder.svg" alt="Hugging Face" style={{ width: '120px', height: '120px' }} />
                </a>
            </div>

            {renderReviews()}

            <div className="card-reviews model-detail-card">
                <div>
                    <h3>Add Your Review</h3>
                    {renderStarReview()}
                    <textarea
                        className="review-textarea"
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                        maxLength={1000}
                        placeholder="Enter a review here (max 1000 characters)"
                    />
                    <button className='review-submit-btn' onClick={submitReview}>Submit Review</button>
                </div>
            </div>
            {showSignInModal && <ModalSignIn onClose={handleCloseModal} />}
        </div>
    );
}

export default ModelDetail;