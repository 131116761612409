import React, { useState } from 'react';
import axios from 'axios';
import './ChangePassword.css'; // Ensure this CSS file contains the necessary styles

function ChangePassword() {
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if new password matches confirm password
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      setErrorMessage('New password and confirm password do not match.');
      return;
    }
    
    try {
      // Make API call to change password
      // Ensure you send the correct payload as expected by your backend
      await axios.post(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/change-user-pass`, {
        password: passwords.newPassword  // Adjusted to match Flask expectation
      });
      setErrorMessage('Password changed successfully!');
      // Clear input fields after successful password change
      setPasswords({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      });
    } catch (error) {
      console.error('Error changing password:', error);
      setErrorMessage('Failed to change password.');
    }
  };
  

  return (
    <section className="change-password-section">
      <div className="form-box">
        <h2>Change Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputbox">
            <input
              type="password"
              name="currentPassword"
              required
              value={passwords.currentPassword}
              onChange={handleChange}
            />
            <label>Current Password</label>
          </div>
          <div className="inputbox">
            <input
              type="password"
              name="newPassword"
              required
              value={passwords.newPassword}
              onChange={handleChange}
            />
            <label>New Password</label>
          </div>
          <div className="inputbox">
            <input
              type="password"
              name="confirmNewPassword"
              required
              value={passwords.confirmNewPassword}
              onChange={handleChange}
            />
            <label>Confirm New Password</label>
          </div>
          <button type="submit" className="update-password-button">
            Change Password
          </button>
          <p>Note: If you have are logged in with a google account your account is managed by google auth and does not have a password</p>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
    </section>
  );
}

export default ChangePassword;
