import React from 'react';
import './FortaifyMission.css';

function Mission() {
  return (
    <div className="mission-container">
      <h1 className="mission-title">Mission</h1>
      <div className="mission-statement card">
        <p>
        Our mission is to revolutionize the way people interact with text by transforming it into more accessible and versatile formats. We believe in the power of AI to unlock the vast potential of written content, turning it into vivid images, expressive sound effects, and dynamic speech. Our suite of products, including Audioverse, Visionary, and Glyph, is designed to cater to the diverse needs of our users, providing an all-in-one solution for creative content generation and enhanced communication. By prioritizing privacy, innovation, and user experience, we strive to make advanced AI and ML models accessible to everyone, empowering individuals and organizations to bring their creative visions to life. Fortiafy is not just about text transformation; it's about setting your imagination free        </p>
      </div>
      <div className="mission-sections">
        <div className="mission-section card">
          <h2>Innovation</h2>
          <p>We're dedicated to pushing the boundaries of what's possible with AI and ML technologies, continuously exploring new frontiers in text transformation. Our commitment to innovation drives us to develop cutting-edge solutions like Audioverse, Visionary, and Glyph, enabling our users to convert text into various media formats effortlessly. By fostering a culture of creativity and technological advancement, we aim to lead the industry in providing versatile, innovative products that unlock the creative potential of text.</p>
        </div>
        <div className="mission-section card">
          <h2>Privacy</h2>
          <p> Champion your right to privacy with unwavering commitment. Our philosophy is simple: the only personal data we collect is the minimal information required to create your account. Beyond this, your interactions with our AI-powered products are designed to run locally on your device, ensuring that the creative content you generate never leaves your personal sphere. This approach not only safeguards your privacy but also empowers you to explore and create with peace of mind, knowing that your ideas remain exclusively yours. In a world increasingly concerned with data privacy, Fortiafy stands as a bastion of trust, ensuring that your journey with AI is both secure and personal.</p>
        </div>
        <div className="mission-section card">
          <h2>Accessibility</h2>
          <p>Our core belief is that AI should be inclusive, bridging the gap between advanced technology and everyday creativity. We design our tools with a clear focus on user-friendliness, ensuring that individuals across all levels of technical expertise can leverage the power of AI. This principle drives us to create interfaces that are intuitive and easy to navigate, making sophisticated AI functionalities accessible to everyone. Whether you're a tech-savvy professional or a newcomer to AI, Fortiafy equips you with the tools to unlock your creative potential without the complexity. By prioritizing inclusivity in our product design, we aim to democratize the use of AI, enabling a broader audience to explore, create, and innovate with confidence.</p>
        </div>
      </div>
      <div style={{ height: '100px' }}></div>
      <div className="white-bar-mission"></div>
    </div>
  );
}

export default Mission;
