import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './visionary.css';
import visionaryCardImage from '../../img/visionary-product-page.png';

function Visionary() {
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const toggleMoreInfo = () => {
    setShowMoreInfo(prevState => !prevState);
  };

  return (
    <div>
      <div className="flex-container-right">
        <div className="move-right">
          <div className="product-container">
            <div className="product-image">
              <img src={visionaryCardImage} alt="Visionary Screenshot" />
            </div>
            <div className="product-info">
              <h3>Visionary</h3>
              <p>
              Equipped with the latest AI technology, Visionary caters to creators across all domains, from graphic designers and digital artists to marketers and storytellers, providing a seamless pathway to visual realization. This tool marries groundbreaking technology with an intuitive interface, making sophisticated image generation accessible and privacy-focused, by ensuring all operations are securely managed on your desktop. Whether you’re visualizing concepts for a digital masterpiece, generating visuals for engaging content, or simply exploring the bounds of your creativity, Visionary empowers you to bring your visual narratives to life. Embrace the power of visual storytelling, where your imagination sets the limits.
              </p>
              <div className="product-buttons">
                <button className="download-button-visionary">Coming Soon</button>
                <button className="contact-button-visionary" onClick={toggleMoreInfo}>Learn More</button>
                <NavLink to="/VisionaryReviews">
                  <button className="resume-button-visionary">Reviews</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMoreInfo && (
        <div className="pricing-tier-cards-container">
          <div className="pricing-tier-card shown">
            <h3>For Everyone</h3>
            <h2>Visionary Basic</h2>
            <p>$FREE / month</p>
            <p> • use up to 1 computer per account</p>
            <ul>
              <li className="checkmark">Transform text into images with AI</li>
              <li className="checkmark">Access a vast library of styles and formats</li>
              <li className="checkmark">Create unlimited images</li>
              <li className="checkmark">Completely local and private image generation</li>
              <li className="checkmark">No internet Needed</li>
              <li className="crossmark">Advanced image customization options</li>
              <li className="crossmark">Priority access to new features and models</li>
              <li className="crossmark">Enhanced resolution and quality for images</li>
              <li className="crossmark">Professional support and tutorials</li>
            </ul>
            <button className="download-button-visionary">Coming Soon</button>
          </div>

          <div className="pricing-tier-card shown">
            <h3>For Creatives and Professionals</h3>
            <h2>Visionary Pro</h2>
            <p>Pricing Coming soon</p>
            <p>• Up to 3 computers per account</p>
            <ul>
              <li className="checkmark">Transform text into images with AI</li>
              <li className="checkmark">Access a vast library of styles and formats</li>
              <li className="checkmark">Create unlimited images</li>
              <li className="checkmark">Completely local and private image generation</li>
              <li className="checkmark">No internet Needed</li>
              <li className="checkmark">Advanced image customization options</li>
              <li className="checkmark">Priority access to new features and models</li>
              <li className="checkmark">Enhanced resolution and quality for images</li>
              <li className="checkmark">Professional support and tutorials</li>
            </ul>
            <button className="download-button-visionary">Coming Soon</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Visionary;
