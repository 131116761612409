import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import './DeleteAccount.css';

function DeleteAccount() {
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const { isLoggedIn, logOut } = useAuth();

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmationChange = (e) => setConfirmation(e.target.value.toUpperCase());

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/logout`, {}, { withCredentials: true });
      logOut();
      navigate('/login');
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload(true);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleDeleteAccount = async () => {
    if (confirmation !== "DELETE") {
      setErrorMessage("You must type 'DELETE' to confirm.");
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/delete-user-account`, {
        data: {
          password: password,
        },
        withCredentials: true,
      });
      alert('Account deleted successfully.');
      handleLogout();
    } catch (error) {
      console.error('Error deleting account:', error);
      setErrorMessage('Failed to delete account. Make sure to use same google account that is logged in. Please try again or contact support if the problem persists.');
    }
  };

  const handleDeleteAccountGoogle = async (googleId) => {
    try {
      await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/delete-user-account-google`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({ googleId }),
      });
      alert('Account deleted successfully.');
      handleLogout();
    } catch (error) {
      console.error('Error deleting account:', error);
      setErrorMessage('Failed to delete account. Please try again or contact support if the problem persists.');
    }
  };

  const clientId = "180458304189-580uscho7g48h4a6ujc13p6vh64mpvid.apps.googleusercontent.com";

  const onFailure = (res) => {
    console.log("Login Failed! res: ", res);
  };

  const onSuccess = (res) => {
    const googleId = res.profileObj.googleId;
    handleDeleteAccountGoogle(googleId);
  };

  return (
    <section className="delete-account-section">
      <div className="form-box">
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <h2>Delete Account</h2>
        <p className="delete-warning">
          Warning: Deleting your account is irreversible. All your account information will be permanently removed.
        </p>
        <div className="inputbox">
          <input type="password" name="password" required value={password} onChange={handlePasswordChange} />
          <label>Password</label>
        </div>
        <div className="inputbox">
          <input type="text" name="confirmation" required value={confirmation} onChange={handleConfirmationChange} />
          <label>Please Type "DELETE" to confirm</label>
        </div>
        <button className="delete-button" onClick={handleDeleteAccount}>
          Delete My Account
        </button>

        <p className="delete-warning">
          Note: If you are logged in with a Google account, please confirm your identity before deleting your account below instead.
        </p>

        <GoogleLogin
          clientId={clientId}
          buttonText="Confirm Identity to Delete Account"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    </section>
  );
}

export default DeleteAccount;
