import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import ModalSignIn from '../ModalSignIn/ModalSignIn';
import './ImageModelDetail.css';

// Disable vertical scrolling
function disableVerticalScroll() {
    document.body.style.overflow = 'hidden';
  }
  
  // Enable vertical scrolling
  function enableVerticalScroll() {
    document.body.style.overflow = 'auto';
  }

  function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

function getDynamicFontSize(modelName) {
    const baseSize = 24; // Base font size in pixels
    const maxLength = 20; // Maximum length before adjusting size
    if (!modelName) return `${baseSize}px`; // Return base size if no name is provided

    const length = modelName.length;
    if (length <= maxLength) return `${baseSize}px`; 

    // Calculate adjusted size. You might need to tweak the formula to suit your design.
    const adjustedSize = Math.max(baseSize - (length - maxLength), 20); // Don't go below 12px
    return `${adjustedSize}px`;
}


function ImageModelDetail({ ImageGen_id }) {
  const [modelDetails, setModelDetails] = useState(null);
  const [downloads, setDownloads] = useState(0);
  const [isHeartBeating, setIsHeartBeating] = useState(false);
  const [isDislikeHeartBeating, setIsDislikeHeartBeating] = useState(false);
  const [isDownloadIconActive, setIsDownloadIconActive] = useState(false);
  const [reviewText, setReviewText] = useState('');
  const [starReview, setStarReview] = useState(0);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [userLiked, setUserLiked] = useState(false);
  const [userDisliked, setUserDisliked] = useState(false);
  const [comments, setComments] = useState([]);


    const fetchTextModelReviews = async () => {
        try {
            const reviewsResponse = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/image-models-reviews/${ImageGen_id}/read`);
            if (!reviewsResponse.ok) {
                throw new Error('Network response was not ok for reviews');
            }
            const reviewsData = await reviewsResponse.json();
            const sortedComments = reviewsData.sort((a, b) => new Date(b.comment_date) - new Date(a.comment_date));
    
            setComments(sortedComments);
        } catch (error) {
            console.error('Fetch reviews error:', error);
        }
    };

    useEffect(() => {
        // Disable scrolling on mount
        document.body.style.overflowY = 'hidden';
      
        // Re-enable scrolling when the component unmounts
        return () => {
          document.body.style.overflowY = 'auto';
        };
      }, []);
      

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/image-model-details/${ImageGen_id}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setModelDetails(data);
            setLikes(data.likes);
            setDislikes(data.dislikes);
            setDownloads(data.num_downloads); // Set the initial download count
          } catch (error) {
            console.error('Fetch error:', error);
          }
        };
      
        fetchData();
        fetchTextModelReviews();
      }, [ImageGen_id]);
      
      const handleLikeClick = async () => {
        if (userDisliked) {
            setDislikes(dislikes - 1);
            setUserDisliked(false);
        }
    
        setIsHeartBeating(true);
        setTimeout(() => setIsHeartBeating(false), 500);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/image-models-likes/${ImageGen_id}`, {
                method: 'POST',
                credentials: 'include',
            });

            if (response.status === 401) {
                setShowSignInModal(true);
                return; 
            }
        
            const data = await response.json();
    
            // Update likes based on the server's response
            setLikes(data.likes);
            setUserLiked(data.action === "liked");
            setUserDisliked(false); // Ensure this is false when a like is successful
        } catch (error) {
            console.error('Error updating likes:', error);
        }
    };
    

    const handleDislikeClick = async () => {
        if (userLiked) {
            setLikes(likes - 1);
            setUserLiked(false);
        }
    
        setIsDislikeHeartBeating(true);
        setTimeout(() => setIsDislikeHeartBeating(false), 500);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/image-models-dislikes/${ImageGen_id}`, {
                method: 'POST',
                credentials: 'include',
            });
            if (response.status === 401) {
                setShowSignInModal(true);
                return; 
            }

            const data = await response.json();
    
            // Update dislikes based on the server's response
            setDislikes(data.dislikes);
            setUserDisliked(data.action === "disliked");
            setUserLiked(false); // Ensure this is false when a dislike is successful
        } catch (error) {
            console.error('Error updating dislikes:', error);
        }
    };
    
    const handleDownloadClick = async (e) => {
        e.preventDefault(); // Prevent the default link behavior
        setIsDownloadIconActive(true);
        setTimeout(() => {
            setIsDownloadIconActive(false);
        }, 500);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/image-models/${ImageGen_id}/increment-download`, {
                method: 'POST',
                credentials: 'include', 
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Create a new anchor element to initiate the download
            const downloadLink = document.createElement('a');
            downloadLink.href = modelDetails.download_url;
            downloadLink.target = '_blank';
            downloadLink.click();

            const updatedDownloads = await response.json();
            setDownloads(updatedDownloads.downloads);

        } catch (error) {
            console.error('Failed to increment download count:', error);
        }
    };

  const submitReview = async () => {
    const review = {
        Rating: starReview,
        CommentText: reviewText,
    };
    
    try {
        const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/image-models-reviews/${ImageGen_id}/write`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(review),
        });

        if (response.status === 401) {
            setShowSignInModal(true);
            return; 
        }

        if (response.status == 400) {
            
            return;
        }

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Reset review form upon successful submission
        setReviewText('');
        setStarReview(0);
        
        // Refresh comments to include the new one
        await fetchTextModelReviews();
    } catch (error) {
        console.error('Submit review error:', error);
        alert('There was a problem saving your review. Please try again.');
    }
};

  const handleStarReview = (review) => {
      setStarReview(review);
  };
  

  const renderInfo = (label, data) => {
      return <p><strong>{label}:</strong> {data}</p>;
  };

  const renderReviews = () => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    enableVerticalScroll();

    return comments.length > 0 ? (
        <div className="image-review-card-area">
            <div className="image-reviews-card-list">
                {comments.map((review) => (
                    <div key={review.CommentID} className="review-card">
                        <div className="text-review-rating-cards">
                            {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                        </div>
                        <div className="text-review-text">{review.comment_text}</div>
                        <div className="text-review-username">By: {review.username}</div>
                        <div className="text-review-timestamp">Date: {formatDate(review.comment_date)}</div>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <p className="image-no-text-reviews">No reviews yet. Be the first?</p>
    );
};


  const renderImages = (imagesData) => {
    return (
      <div className="image-gallery-container">
        {imagesData.map((image, index) => (
          <img key={index} src={image} alt={`Gallery Image ${index + 1}`} />
        ))}
      </div>
    );
  };
  
  const renderStarReview = () => {
      return (
          <div>
              {[1, 2, 3, 4, 5].map((star) => (
                  <span key={star} onClick={() => handleStarReview(star)}>
                      {star <= starReview ? '★' : '☆'}
                  </span>
              ))}
          </div>
      );
  };

  if (!modelDetails) {
      return <div className='image-loading-model-detail'>Loading model details...</div>;
  }

  
  const link = modelDetails.model_page_link || 'N/A';
  const author_link = modelDetails.author_link || 'N/A';
  const download_link = modelDetails.download_url || 'N/A';
  console.log(download_link);
  const model_page_link = modelDetails.model_page_link || 'N/A';

      const handleCloseModal = () => {
        setShowSignInModal(false);
    };

  return (
      <div className="image-model-detail-container">

        <div className="image-model-detail-header image-model-detail-card">
            <h2 className="model-name" style={{ fontSize: getDynamicFontSize(modelDetails?.model_name) }}>
                {modelDetails?.model_name || 'N/A'}
            </h2>
            {renderInfo('Author Name', modelDetails.author_name || 'N/A')}
            <a href={author_link} target="_blank" rel="noopener noreferrer">
                View Author on Civitai
            </a>
        </div>

        <div className="image-card-model-description">
                <div className="description-content">
                {renderInfo('Model Description ')}
                    {modelDetails.description || 'N/A'}
                </div>
        </div>
        
        <div className="image-card-likes image-model-detail-card" onClick={handleLikeClick}>
            {renderInfo('Likes', likes)}
                    <div className="like-icon-wrapper">
                        <i className={`fa-solid fa-heart like-icon ${isHeartBeating ? 'beat' : ''}`}></i>
                    </div>
        </div>

        <div className="image-card-dislikes image-model-detail-card">
            {renderInfo('Dislikes', dislikes)}
                    <div className="dislike-icon-wrapper" onClick={handleDislikeClick}>
                        <i className={`fa-solid fa-heart-broken dislike-icon ${isDislikeHeartBeating ? 'beat' : ''}`}></i>
                    </div>
        </div>

        <div className="image-card-download image-model-detail-card">
            {renderInfo('Downloads', downloads)}
            <a href={download_link} target="_blank" rel="noopener noreferrer" onClick={handleDownloadClick}>
                <i className="fas fa-download" style={{ fontSize: '6rem', marginRight: '10px', marginBottom: '10px' }}></i>
            </a>
        </div>


        <div className="image-card-huggingface image-model-detail-card">
                {renderInfo('View Model Page')}
                <a href={model_page_link} target="_blank" rel="noopener noreferrer">
                    <img src="https://i0.wp.com/education.civitai.com/wp-content/uploads/2023/07/5000x5000_faviconC.png?w=5000&ssl=1" alt="Hugging Face" style={{ width: '90px', height: '90px' }} />
                </a>
        </div>

        <div className="image-model-images-container image-model-detail-card">
        {renderInfo('Example Images')}
        {modelDetails && typeof modelDetails.other_images === 'string' ? (
            renderImages(JSON.parse(modelDetails.other_images))
        ) : Array.isArray(modelDetails.other_images) ? (
            renderImages(modelDetails.other_images)
        ) : (
            <p>Loading images...</p>
        )}
        </div>


        {renderReviews()}

        <div className="image-card-reviews image-model-detail-card">
            <div>
                <h3>Add Your Review</h3>
                {renderStarReview()}
                <textarea
                    className="image-review-textarea"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    maxLength={1000}
                    placeholder="Enter a review here (max 1000 characters)"
                />
                <button className='image-review-submit-btn' onClick={submitReview}>Submit Review</button>
            </div>
        </div>

        {showSignInModal && <ModalSignIn onClose={handleCloseModal} />}

      </div>
  );
}

export default ImageModelDetail;