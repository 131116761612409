import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './Header.css';
import { useAuth } from '../../AuthContext';
import axios from 'axios';

function Header() {
  const { isLoggedIn, logOut, loading } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/logout`, {}, { withCredentials: true });
      logOut();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      closeLogoutModal();
    }
  };

  const openLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>; // Render a loading state while checking session
  }

  return (
    <header className="fortaify-header">
      <div className="container">
        <NavLink to="/" className="logo">
          <img src="/logo.png" alt="Fortaify Logo" />
          Fortaify
        </NavLink>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <nav>
          <ul className={`links ${isMenuOpen ? 'open' : ''}`}>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home</NavLink>
            <NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>About Us</NavLink>
            <NavLink to="/products" className={({ isActive }) => (isActive ? 'active' : '')}>Products</NavLink>
            <NavLink to="/gallery" className={({ isActive }) => (isActive ? 'active' : '')}>Gallery</NavLink>
            {/* <NavLink to="/news" className={({ isActive }) => (isActive ? 'active' : '')}>News</NavLink> */}
            {isLoggedIn ? (
              <>
                <NavLink to="/account" className={({ isActive }) => (isActive ? 'active' : '')}>Account</NavLink>
                <a href="#" onClick={openLogoutModal}>Logout</a>
              </>
            ) : (
              <NavLink to="/login" className={({ isActive }) => (isActive ? 'active' : '')}>Login</NavLink>
            )}
          </ul>
        </nav>
      </div>
      <Modal
        isOpen={isLogoutModalOpen}
        onRequestClose={closeLogoutModal}
        contentLabel="Logout Confirmation"
        ariaHideApp={false}
      >
        <p>Are you sure you want to logout?</p>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
        <button onClick={closeLogoutModal}>Cancel</button>
      </Modal>
    </header>
  );
}

export default Header;
