import React from 'react';
import './Teams.css';

// Importing the image cards
import BronsonImageCard from '../../img/bronsonAI.jpeg';
import TeagueImageCard from '../../img/IMG_3598_cropped_square.png';
import NickImageCard from '../../img/NickCamacho.png';
import AiAgent1Image from '../../img/Agents/agent1.png';
import AiAgent2Image from '../../img/Agents/agent2.png';
import AiAgent3Image from '../../img/Agents/agent3.png';
import AiAgent4Image from '../../img/Agents/agent4.png';
import AiAgent5Image from '../../img/Agents/agent5.png';

const teamMembers = [
  {
    id: 1,
    name: 'Bronson Woods',
    title: 'Founder & CEO, Lead Software Engineer',
    description: 'Leading our team to success.',
    imageUrl: BronsonImageCard,
    special: true
  },
  {
    id: 2,
    name: 'Teague Schnell',
    title: 'Advisor',
    description: 'Consultant on business vision.',
    imageUrl: TeagueImageCard,
  },
  {
    id: 3,
    name: 'Nick Camacho',
    title: 'Business Manager',
    description: 'Lead advisor and business manager.',
    imageUrl: NickImageCard,
  }
];

const syntheticTeamMembers = [
  {
    id: 1,
    name: 'AI Agent Orion',
    title: 'Software Engineer',
    description: 'Responsible for designing, developing, and maintaining our platform.',
    imageUrl: AiAgent1Image,
  },
  {
    id: 2,
    name: 'AI Agent Vega',
    title: 'Marketer and Social Media Manager',
    description: 'Responsible for developing and executing marketing strategies across various social media platforms.',
    imageUrl: AiAgent2Image, 
  },
  {
    id: 3,
    name: 'AI Agent Altair',
    title: 'Technical News Writer and Researcher',
    description: 'Responsible for researching and writing technical news articles for our platform.',
    imageUrl: AiAgent3Image,
  },
  {
    id: 4,
    name: 'AI Agent Lyra',
    title: 'Technical News Editor',
    description: 'Responsible for editing and publishing technical news articles for our platform.',
    imageUrl: AiAgent4Image, 
  },
];

const TeamMember = ({ name, title, description, imageUrl, special }) => {
  const handleMemberClick = () => {
    if (special) {
      window.open('https://www.buymeacoffee.com/fortaify', '_blank');
    }
  };

  return (
    <div className={`ft-team-member ${special ? 'bronson-special' : ''}`} onClick={handleMemberClick}>
      <img src={imageUrl} alt={`Headshot of ${name}`} className="ft-team-headshot" />
      <h3>{name}</h3>
      <p className="ft-title">{title}</p>
      <p className="ft-description">{description}</p>
    </div>
  );
};

const Teams = () => (
  <section className="ft-team-section">
    <h2>The Brains</h2>
    <div className="ft-team-grid">
      {teamMembers.map(member => (
        <TeamMember key={member.id} {...member} />
      ))}
    </div>
    <h2>The Synthetic Team</h2>
    <div className="ft-team-grid">
      {syntheticTeamMembers.map(member => (
        <TeamMember key={member.id} {...member} />
      ))}
    </div>
  </section>
);

export default Teams;
