import React, { useState, useEffect } from 'react';
import './Gallery.css';
import ModelCardSkeleton from './GallerySkeleton';

// Custom Hook to check window width for responsive design
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

function ModelCard({ model, isLoading }) {
  const { width } = useWindowSize(); // Get the current window width
  const mobileView = width < 768; // Define mobile view breakpoint

  const handleDownloadClick = () => {
    window.open(model.model_link, '_blank', 'noopener,noreferrer');
  };

  const handleMoreInfoClick = () => {
    window.location.href = `/models/${model.modelID}`;
    // console.log(model.modelID);
  };

  const renderStarRating = (rating) => {
    const totalStars = 5;
    let stars = [];
    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <span key={i} className={i <= rating ? "filled-star" : "unfilled-star"}>
          {i <= rating ? '★' : '☆'}
        </span>
      );
    }
    return stars;
  };

  return (
    <div className={`model-card ${mobileView ? "mobile-view" : ""}`}>
      <div className="model-info">
        <h2>{model.model_name}</h2>
        <h3 className="model-rating">Author: {model.author_name}</h3>
      </div>
      <h3>Rating: <span className="rating-stars">{renderStarRating(model.rating)}</span></h3>
      <div className="model-details">
        <span>Parameters: {model.param_size}</span>
        <span>Likes: {model.likes}</span>
        <span>Dislikes: {model.dislikes}</span>
        <span>Downloads: {model.num_downloads}</span>
      </div>
      <div className="button-container">
        <button onClick={handleMoreInfoClick} className="download-button">More Info</button>
        <button onClick={handleDownloadClick} className="MoreInfo-button">View Huggingface</button>
      </div>
    </div>
  );
}

function TextGallery({ filters }) {
  const [models, setModels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 12;
  
  useEffect(() => {
    // console.log("Fetching models for page:", currentPage);
    fetchModels();
}, [currentPage, filters]);

const fetchModels = async () => {
    setIsLoading(true);
    let query = buildQueryString(filters);
    query += `&page=${currentPage}&per_page=${itemsPerPage}`;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/models${query}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const result = await response.json();
      // console.log("Fetched models:", result);
  
      const uniqueModels = removeDuplicateModels(result.models);
      setModels(uniqueModels);
      setTotalPages(result.total_pages);
    } catch (error) {
      console.error("Error fetching models:", error);
      setModels([]);
    } finally {
      setIsLoading(false);
    }
};


  const removeDuplicateModels = (data) => {
    return Array.from(new Map(data.map(model => [model.modelID, model])).values());
  };
  
  const paginate = (pageNumber) => {
    // console.log("Paginating to:", pageNumber);
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const buildQueryString = (filters) => {
    let queryString = '';
    Object.entries(filters).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        if (value.min !== undefined) {
          if (value.min !== '') queryString += `${key}Min=${value.min}&`;
          if (value.max !== '') queryString += `${key}Max=${value.max}&`;
        }
      } else {
        queryString += `${key}=${value}&`;
      }
    });
    if (queryString.endsWith('&')) {
      queryString = queryString.slice(0, -1);
    }
    return queryString ? `?${queryString}` : '';
  };

  const renderPaginationControls = () => {
    return <>
      <button onClick={() => paginate(1)} disabled={currentPage === 1}>First</button>
      <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
      {Array.from({ length: totalPages }, (_, i) => i + 1)
        .slice(Math.max(currentPage - 2, 0), Math.min(currentPage + 1, totalPages))
        .map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={currentPage === number ? 'active' : ''}
          >
            {number}
          </button>
        ))}
      <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
      <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>Last</button>
    </>;
  };

  if (isLoading) {
    return (
      <div className="models">
        <h1>Explore AI Models</h1>
        <div className="model-list">
          {Array.from(new Array(itemsPerPage)).map((_, index) => (
            <ModelCardSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentModels = models.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="models">
      <h1>Explore AI Models</h1>
      <div key={currentPage} className="model-list">
          {models.map((model) => (
              <ModelCard key={model.modelID} model={model} isLoading={isLoading} />
          ))}
      </div>
      {!isLoading && totalPages > 1 && (
        <div className="pagination">
          {renderPaginationControls()}
        </div>
      )}
    </div>
  );
  
}

export default TextGallery;
