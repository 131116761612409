import React from 'react';
import ResetPassword from './LoginPage/ResetPassword';

const ResetPasswordPage = () => {
  return (
    <div>
      <ResetPassword />
    </div>
  );
}

export default ResetPasswordPage;