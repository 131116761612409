import React from 'react';
import Login from './LoginPage/Login';

const LoginPage = () => {
  return (
    <div>
      <Login />
    </div>
  );
}

export default LoginPage;