import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './audioverse.css';
import audioverseCardImage from '../../img/audioverse-product-page.png';

function AudioVerse() {
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const toggleMoreInfo = () => {
    setShowMoreInfo(prevState => !prevState);
  };
  

  return (
    <div>
      <div className="product-container">
        <div className="product-image">
        <img src={audioverseCardImage} alt="Glyph Screenshot" />
        </div>
        <div className="product-info">
          <h3>AudioVerse</h3>
          <p>
          Elevate your creative projects with the power of sound. Audioverse leverages cutting-edge AI technology to transform text descriptions into rich, immersive audio experiences. Designed for podcasters, video creators, and storytellers alike, this tool offers a straightforward way to generate soundscapes, effects, and narrations directly from text. Featuring an intuitive interface, Audioverse simplifies the audio creation process, making it accessible to creators of all skill levels while ensuring your projects remain distinctively yours in a privacy-first environment. Whether enhancing narratives with dynamic sound effects, creating background ambiences, or vocalizing text for diverse applications, Audioverse is your gateway to unlocking the full potential of your audio creations. Step into a world where your stories are not just told, but truly heard
        </p>
          <div className="product-buttons">
            <button className="download-button-audioverse">Coming Soon</button>
            <button className="contact-button-audioverse" onClick={toggleMoreInfo}>Learn More</button>
            <NavLink to="/AudioVerseReviews">
              <button className="resume-button-glyph">Reviews</button>
            </NavLink>
          </div>
        </div>
      </div>
      {showMoreInfo && (
        <div className="pricing-tier-cards-container">
          <div className="pricing-tier-card shown">
            <h3>For Enthusiasts</h3>
            <h2>AudioVerse Basic</h2>
            <p>$FREE / month</p>
            <p> • Single user</p>
            <ul>
              <li className="checkmark">Generate unlimited AI-powered speech in various voices</li>
              <li className="checkmark">Create unique sound effects based on text descriptions</li>
              <li className="checkmark">Access to a vast open-source library of fine-tuned audio models</li>
              <li className="checkmark">Download as many models as you'd like for local use</li>
              <li className="checkmark">Completely local and private interaction with AI</li>
              <li className="checkmark">No internet needed for operation</li>
              <li className="crossmark">Customize voice parameters to fit your needs</li>
              <li className="crossmark">Ability to convert entire text documents into audiobooks</li>
              <li className="crossmark">Background music generator for your projects</li>
            </ul>
            <button className="download-button-audioverse">Coming Soon</button>
          </div>

          <div className="pricing-tier-card shown">
            <h3>For Creatives and Professionals</h3>
            <h2>AudioVerse Pro</h2>
            <p>Pricing Coming soon</p>
            <p> • Up to 3 users</p>
            <ul>
              <li className="checkmark">Advanced text-to-speech features with customizable emotions</li>
              <li className="checkmark">High-quality sound effect generation for professional use</li>
              <li className="checkmark">Exclusive access to premium audio model library</li>
              <li className="checkmark">Unlimited downloads for offline model usage</li>
              <li className="checkmark">Secure, local processing ensures privacy</li>
              <li className="checkmark">Works offline, perfect for remote or on-the-go projects</li>
              <li className="checkmark">Upload scripts or text files for instant audio conversion</li>
              <li className="checkmark">Multi-track audio mixing and editing capabilities</li>
              <li className="checkmark">Advanced music composition tools</li>
            </ul>
            <button className="download-button-audioverse">Coming Soon</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AudioVerse;
