import React, { useState } from 'react';
import './Glyph.css';
import { NavLink } from 'react-router-dom';
import glyphCardImage from '../../img/glyph-product-page.png';

function Glyph() {
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };
  
  return (
    <div>
      <div className="product-container">
        <div className="product-image">
          <img src={glyphCardImage} alt="Glyph Screenshot" />
        </div>
        <div className="product-info">
          <h3>Glyph</h3>
          <p>
          Built with state-of-the-art AI, Glyph is designed for anyone looking to elevate their writing, from authors and content creators to developers and marketers. It combines the power of cutting-edge technology with an intuitive, user-friendly interface, ensuring your ideas are effortlessly transformed into words. Glyph prioritizes your privacy, operating securely on your desktop to keep your intellectual creations confidential. Whether you’re crafting the next bestselling novel, populating a website with engaging content, or generating innovative code, Glyph is your ultimate tool for versatile, dynamic text creation. Welcome to a world where your words know no bounds.
          </p>
          <div className="product-buttons">
            <button className="download-button-glyph">Coming Soon</button>
            <button className="contact-button-glyph" onClick={toggleMoreInfo}>Learn More</button>
            <NavLink to="/GlyphReviews">
              <button className="resume-button-glyph">Reviews</button>
            </NavLink>
          </div>
        </div>
      </div>
      {showMoreInfo && (
        <div className="pricing-tier-cards-container">
          <div className={`pricing-tier-card shown`}>
            <h3>For Everyone</h3>
            <h2>Glyph Basic</h2>
            <p>$FREE / month</p>
            <p> • Up to 1 computer per account</p>
            <ul>
              <li className="checkmark">AI chat to talk about anything you would like</li>
              <li className="checkmark">Access to massive open source library of LLMs</li>
              <li className="checkmark">Download as many models as you'd like</li>
              <li className="checkmark">Completely local and private interaction with AI</li>
              <li className="checkmark">No internet Needed</li>
              <li className="crossmark">Ability to play with multimodal models</li>
              <li className="crossmark">Chat with your documents</li>
              <li className="crossmark">Chat with custom made AI characters</li>
              <li className="crossmark">Have AI read any text you would like with a realistic human voice</li>
            </ul>
            <button className="download-button-glyph">Coming Soon</button>
          </div>

          <div className={`pricing-tier-card shown`}>
            <h3>For those who want more AI tools</h3>
            <h2>Glyph Pro</h2>
            <p>Pricing Coming soon</p>
            <p>• Up to 3 computers per account</p>
            <ul>
              <li className="checkmark">AI chat to talk about anything you would like</li>
              <li className="checkmark">Access to massive open source library of LLMs</li>
              <li className="checkmark">Download as many models as you'd like</li>
              <li className="checkmark">Completely local and private interaction with AI</li>
              <li className="checkmark">No internet Needed</li>
              <li className="checkmark">Ability to play with multimodal models</li>
              <li className="checkmark">Chat with your documents</li>
              <li className="checkmark">Chat with custom made AI characters</li>
              <li className="checkmark">Have AI read any text you would like with a realistic human voice</li>
            </ul>
            <button className="download-button-glyph">Coming Soon</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Glyph;