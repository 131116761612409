import React from 'react';
import './Products.css';


function ProductInfo() {
  return (
    <div className="product-page">
        <h1 className="about-products">Here's what we have to offer!</h1>
    </div>
  );
}

export default ProductInfo;