import React from 'react';
import './FortaifyVision.css';

function Vision() {
  return (
    <div className="vision-container">
        <div style={{ height: '100px' }}></div>
      <h1 className="vision-title">Vision</h1>
      <div className="vision-statement">
        <p>We envision a world where creativity knows no bounds, and the barrier between thought and creation is effortlessly dissolved. Our mission is to democratize access to advanced AI and machine learning technologies, making it possible for everyone, from artists to entrepreneurs, to bring their visions to life. By providing intuitive, powerful tools like Audioverse, Visionary, and Glyph, we aim to unlock the infinite potential of text-based content, transforming it into rich, engaging, and diverse media formats. In doing so, we strive to foster a global community where innovation thrives, privacy is respected, and the transformative power of AI is accessible to all. Fortaify is not just about building technology; it's about building a future where every voice can be heard, every idea can be seen, and every dream can be realized.</p>
      </div>

      <h1 className="vision-title">Values</h1>

      <div className="values-container">
      <div className="value-item">
        <p>Fortaify stands at the forefront of the technological revolution, embodying innovation and the democratization of artificial intelligence. It's a beacon for those seeking to harness the power of AI without the complexities often associated with such advanced technologies. By offering subscriptions to an array of open-source AI and ML models, Fortaify empowers its users—ranging from hobbyists to professionals—to effortlessly transform text into speech, sound effects, images, and more. This accessibility is pivotal in bridging the gap between sophisticated technology and the general public, ensuring that the benefits of AI can be enjoyed by a wider audience.

At the heart of Fortaify lies a commitment to privacy and user empowerment. In an era where data privacy concerns are escalating, Fortaify's approach is refreshingly user-centric, providing desktop applications like Visionary and Glyph that run open-source AI models locally. This not only ensures that users retain control over their data but also enhances the reliability and speed of content creation. Fortaify’s focus on privacy is a testament to its dedication to fostering a secure and trustful environment, where users can explore and create without apprehension about their data being misused.

Furthermore, Fortaify’s mission to unlock the creative potential of text is revolutionary. By offering tools like Audioverse, Visionary, and Glyph, it equips users with the ability to seamlessly convert text into audio, images, and even videos, thus breaking down the barriers to creative expression. This unleashes a realm of possibilities for content creators, educators, marketers, and anyone looking to enhance their digital narratives. Fortaify is not just about providing tools; it's about enabling a new form of creativity that marries the art of storytelling with the precision of technology, thereby shaping the future of how we interact with text-based content.</p>
      </div>
      </div>
      <div style={{ height: '100px' }}></div>
    </div>
  );
}

export default Vision;
