import React, { useEffect } from 'react';
import Footer from './Footer/Footer';
import VisionaryReview from './ProductsReviewPage/VisionaryReview';

const VisionaryReviewPage = () => {

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <VisionaryReview />
      <Footer />
    </div>
  );
}

export default VisionaryReviewPage;