import React, { useEffect } from 'react';
import './Terms.css';

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Terms">
      <div className="Terms-container">
      <h1 className="Terms-title">Terms of Service</h1>
        <p className="Terms-text">
          Last Updated: [Date] (please replace [Date] with the actual date of the last update)
        </p>
        <h2>Introduction</h2>
        <p className="Terms-text">
          Welcome to Fortaify! These Terms of Service ("Terms") govern your access to and use of Fortaify's websites, applications, and services (collectively, "Services"). By accessing or using our Services, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of these Terms, you may not use our Services.
        </p>

        <h2>Services Description</h2>
        <p className="Terms-text">
          Fortaify provides subscription-based access to a suite of artificial intelligence (AI) and machine learning (ML) models for text-to-speech, text-to-sound effects, text-to-image, and text-to-text generation (collectively, "AI Services"). Our products, including Audioverse, Visionary, and Glyph, are designed to empower users to transform text into various media formats for creative content generation and communication purposes.
        </p>

        <h2>User Accounts</h2>
        <p className="Terms-text">
          a. Account Creation: To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.<br />
          b. Account Responsibility: You are responsible for safeguarding your account password and for any activities or actions under your account. Fortaify cannot and will not be liable for any loss or damage arising from your failure to comply with this security obligation.
        </p>

        <h2>User Content</h2>
        <p className="Terms-text">
          a. Content Ownership: You retain all rights in, and are solely responsible for, the content you create using our Services.<br />
          b. License to Fortaify: By creating content using our Services, you grant Fortaify a non-exclusive, worldwide, royalty-free license to use, reproduce, display, and perform the content as permitted through the functionality of the Services and under these Terms.
        </p>

        <h2>Use Restrictions</h2>
        <p className="Terms-text">
          You agree not to use the Services in any manner that:
        </p>
        <ul>
          <li>Is illegal, or promotes illegal activities.</li>
          <li>Infringes on the intellectual property rights of others.</li>
          <li>Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable.</li>
        </ul>

        <h2>Intellectual Property</h2>
        <p className="Terms-text">
          The Services and all related graphics, logos, service marks, and trade names used on or in connection with our Services are the trademarks of Fortaify and may not be used without permission in connection with any third-party products or services.
        </p>

        <h2>Termination</h2>
        <p className="Terms-text">
          Fortaify may terminate or suspend your access to the Services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
        </p>

        <h2>Limitation of Liability</h2>
        <p className="Terms-text">
          Fortaify shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.
        </p>

        <h2>Changes to Terms</h2>
        <p className="Terms-text">
          Fortaify reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </p>

        <h2>Governing Law</h2>
        <p className="Terms-text">
          These Terms shall be governed and construed in accordance with the laws of the State of [Your State], United States, without regard to its conflict of law provisions.
        </p>

        <h2>Contact Information</h2>
        <p className="Terms-text">
          If you have any questions about these Terms, please contact us at [Your Contact Information].
        </p>

        <h2>Miscellaneous</h2>
        <p className="Terms-text">
          Your use of the Services is subject to the additional disclaimers and caveats that may appear throughout the site and with specific services or products.
        </p>
      </div>
    </div>
  );
}

export default Terms;
