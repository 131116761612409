import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import './GlyphReview.css';
import ModalSignIn from '../ModalSignIn/ModalSignIn';


function VisionaryReview() {
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState('');
  const [newRating, setNewRating] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [showSignInModal, setShowSignInModal] = useState(false);
  
  // Check if user is signed in    
  const { user } = useAuth(); // Assuming useAuth provides an object with a user property indicating the signed-in user

    // Fetch reviews when the component mounts
    useEffect(() => {
      const fetchReviews = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/visionary-reviews-read`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setReviews(data.map(review => ({
            ...review,
            timestamp: review.timestamp ? new Date(review.timestamp) : new Date()
            // Convert timestamp strings to Date objects, or use current date if absent
          })));
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
      };
  
      fetchReviews();
    }, []);

    const fetchReviews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/visionary-reviews-read`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setReviews(data.map(review => ({
          ...review,
          timestamp: review.timestamp ? new Date(review.timestamp) : new Date()
          // Convert timestamp strings to Date objects, or use current date if absent
        })));
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
  
  const submitReview = async () => {


    // Prepare the data to match your API's expected format
    const reviewData = {
        CommentText: newReview,
        Rating: newRating,
    };

      try {
        const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/visionary-reviews-write`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reviewData),
        });
    
        // Handle unauthorized status before checking response.ok
        if (response.status === 401) {
            setShowSignInModal(true);
            return; // Exit early since we don't want to proceed in this case
        }
    
        setNewReview('');
        setNewRating(0);
        setCharacterCount(0);
        fetchReviews();
        
    } catch (error) {
        console.error('Error posting review:', error);
        alert('There was a problem saving your review. Please try again.');
    }
};


  const handleReviewChange = (event) => {
    setNewReview(event.target.value);
    setCharacterCount(event.target.value.length);
  };

  const renderStars = () => {
    return [...Array(5)].map((_, i) => {
      return (
        <span
          key={i}
          className={`star${i < newRating ? ' selected' : ''}`}
          onClick={() => setNewRating(i + 1)}
        >
          ★
        </span>
      );
    });
  };
  
  return (
    <div className="glyph-review">
      <h2>Write a Review For Visionary</h2>
      <div className="star-rating">
        {renderStars()}
        <p>Click on a star to change your rating 1 - 5, where 5 = great! and 1 = really bad</p>
      </div>
      <div className="review-input">
        <textarea
          placeholder="Add your review"
          value={newReview}
          onChange={handleReviewChange}
          maxLength="1000"
        />
        <div className="character-count">
          {1000 - characterCount} Characters remaining
        </div>
      </div>
      <button onClick={submitReview}>Submit Review</button>
      <div className="reviews-display">
      <h3>Customer Reviews</h3>
      {reviews.length > 0 ? (
        <div className="reviews-list">
          {[...reviews].sort((a, b) => new Date(b.CommentDate) - new Date(a.CommentDate))
            .map((review, index) => (
            <div key={index} className="review-card">
              {/* Display the username */}
              <div className="review-meta">
                <span className="review-username">{review.Username}</span>
                <span className="review-rating">
                  {"★".repeat(review.Rating) + "☆".repeat(5 - review.Rating)}
                </span>
              </div>
              {/* Review text */}
              <p className="review-text">{review.CommentText}</p>
              <p className= "review-username">By: {review.UserID}</p>
              {/* Review timestamp */}
              <div className="review-timestamp">
                {review.CommentDate ? new Date(review.CommentDate).toLocaleString() : "Date unavailable"}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No reviews yet. Be the first to write a review!</p>
      )}
    </div>
      {showSignInModal && <ModalSignIn onClose={() => setShowSignInModal(false)} />}
    </div>
  );
}

export default VisionaryReview;
