import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import './DisplayArticle.css';
import ModalSignIn from '../ModalSignIn/ModalSignIn';
import ArticleSkeletonLoader from './ArticleSkeletonLoader';

function DisplayArticlePage() {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [showSignInModal, setShowSignInModal] = useState(false);

  useEffect(() => {
    const fetchArticleData = async () => {
      setIsLoading(true);
      try {
        const articleResponse = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/articles/${articleId}`);
        const articleData = await articleResponse.json();
        setArticle(articleData);

        const userLikeStatusResponse = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/articles/${articleId}/user-like-status`, {
          credentials: 'include',
        });
        if (userLikeStatusResponse.ok) {
          const { userLiked, userDisliked } = await userLikeStatusResponse.json();
          setLiked(userLiked);
          setDisliked(userDisliked);
        }

        const likeCountResponse = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/articles/${articleId}/like-count`);
        const counts = await likeCountResponse.json();
        setLikeCount(counts.likes);
        setDislikeCount(counts.dislikes);

        const commentsRes = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/articles/${articleId}/comments`);
        const commentsData = await commentsRes.json();
        setComments(commentsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticleData();
  }, [articleId]);

  const revertOptimisticCommentUpdate = () => {
    setComments(prevComments => prevComments.slice(0, -1));
  };
  

  const handlePostComment = async () => {
    if (!newComment.trim()) return;
  
    const tempComment = {
      content: newComment,
      user: "Temporary User",
      date: new Date().toISOString(),
    };
  
    setComments(prevComments => [...prevComments, tempComment]);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/articles/${articleId}/write-comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ content: newComment }),
      });
      if (response.ok) {
        refreshComments();
        setNewComment('');
      } else {
        revertOptimisticCommentUpdate();
        console.error('Failed to post comment');
      }
    } catch (error) {
      revertOptimisticCommentUpdate();
      console.error('Error posting comment:', error);
    }
  };
  

  const refreshComments = async () => {
    const commentsRes = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/articles/${articleId}/comments`);
    const commentsData = await commentsRes.json();
    setComments(commentsData);
  };

  const createMarkup = (markdownText) => {
    return { __html: marked(markdownText) };
  };

  const optimisticUpdateLikes = (like) => {
    if (like) {
      setLikeCount(liked ? likeCount - 1 : likeCount + 1);
      if (disliked) {
        setDislikeCount(dislikeCount - 1);
        setDisliked(false);
      }
    } else {
      setDislikeCount(disliked ? dislikeCount - 1 : dislikeCount + 1);
      if (liked) {
        setLikeCount(likeCount - 1);
        setLiked(false);
      }
    }
  };

  const revertOptimisticUpdate = (like) => {
    if (like) {
      setLikeCount(liked ? likeCount + 1 : likeCount - 1);
      if (!disliked) setDislikeCount(dislikeCount + 1);
    } else {
      setDislikeCount(disliked ? dislikeCount + 1 : dislikeCount - 1);
      if (!liked) setLikeCount(likeCount + 1);
    }
  };

  const handleLikeDislike = async (like) => {
    const action = like ? 'like' : 'dislike';
    const previouslyLiked = liked;
    const previouslyDisliked = disliked;

    setLiked(like ? !liked : liked);
    setDisliked(!like ? !disliked : disliked);
    optimisticUpdateLikes(like);

    try {
      const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/articles/${articleId}/${action}`, {
        method: 'POST',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(`Failed to ${action} article`);
      }
    } catch (error) {
      console.error(`Error updating ${action}:`, error);
      setLiked(previouslyLiked);
      setDisliked(previouslyDisliked);
      revertOptimisticUpdate(like);
    }
  };

  const handleLike = () => handleLikeDislike(true);
  const handleDislike = () => handleLikeDislike(false);

  return (
    <div className="article-article">
      <div className="article-article-detail-page">
        {isLoading ? (
          <ArticleSkeletonLoader />
        ) : article ? (
          <>
            <h1>{article.title}</h1>
            {article.article_images && (
              <img src={`data:image/png;base64,${article.article_images}`} alt="Article" style={{ maxWidth: '100%' }} />
            )}
            <p>Author: {article.author}</p>
            <p>Published on: {article.publication_date}</p>
            <div className="article-article-content" dangerouslySetInnerHTML={createMarkup(article.content)} />
          </>
        ) : (
          <p>Article not found</p>
        )}
      </div>

      <div className="article-wrapper">
        {showSignInModal && <ModalSignIn onClose={() => setShowSignInModal(false)} />}
        <div className="article-button-container">
          <div className="article-button" onClick={handleLike}>
            <span className="article-content">
              {liked ? (
                <i className="article-icon fa fa-heart" aria-hidden="true"></i>
              ) : (
                <i className="article-icon far fa-heart" aria-hidden="true"></i>
              )}
              <span className="article-text">Like</span>
              <span className="article-number">{likeCount}</span>
            </span>
          </div>

          <div className="article-button" onClick={handleDislike}>
            <span className="article-content">
              {disliked ? (
                <i className="article-icon fa fa-heart-broken" aria-hidden="true"></i>
              ) : (
                <i className="article-icon far fa-heart" aria-hidden="true"></i>
              )}
              <span className="article-text">Dislike</span>
              <span className="article-number">{dislikeCount}</span>
            </span>
          </div>
        </div>
      </div>

      <div className="article-article-comments-container">
        <div className="article-comment-form">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Leave a comment"
          />
          <button onClick={handlePostComment}>Add Comment</button>
        </div>

        <div className="article-article-comments">
          <h2>Comments</h2>
          {comments.map((comment, index) => (
            <div key={index} className="article-comment">
              <p>{comment.content}</p>
              <p className='article-comment-sub-info'>By: {comment.user}</p>
              <p className="article-comment-sub-info">Date: {comment.date}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DisplayArticlePage;
