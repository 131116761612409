// ModelCardSkeleton.js
import React from 'react';
import { Skeleton } from '@mui/material';
import './Gallery.css';

function ModelCardSkeleton() {
  return (
    <div className="model-card">
      <Skeleton variant="rectangular" height={40}/>
      <div className="model-details">
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="90%" />
        
      </div>
      <div className="button-container">
        <Skeleton variant="rectangular" height={30} width="48%" style={{ marginRight: '4%' }} />
        <Skeleton variant="rectangular" height={30} width="48%" />
      </div>
    </div>
  );
}

export default ModelCardSkeleton;
