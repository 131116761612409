import React, { useEffect, useState } from 'react';
import Sidebar from './GalleryPage/Sidebar';
import TextGallery from './GalleryPage/Gallery';
import ImageGallery from './GalleryPage/ImageGallery';
import AudioGallery from './GalleryPage/AudioGallery';


const GalleryPage = () => {
    const [selectedComponent, setSelectedComponent] = useState('All');
    const [filters, setFilters] = useState({
        modelSize: { min: null, max: null },
        parameters: { min: null, max: null },
        sortByDownloads: false,
        sortByLikes: false,
        sortbyRating: false,
        nsfw: false,
        searchModel: '',
        searchAuthor: '',
    });

    const onFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        onFilterChange(filters);
    }, [filters]);

    const handleOptionSelect = (option) => {
        setSelectedComponent(option);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };
            
    const renderComponent = () => {
        switch (selectedComponent) {
            case 'Text':
                return <TextGallery filters={filters} />;
            case 'Image':
                return <ImageGallery filters={filters} />;
            case 'Audio':
                return <AudioGallery filters={filters} />;
            default:
                return <TextGallery filters={filters} />;
        }
    };

    return (
        <div>
            <Sidebar onOptionSelect={handleOptionSelect} onFilterChange={handleFilterChange} />
            {renderComponent()}
        </div>
    );
}

export default GalleryPage;
