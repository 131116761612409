import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// import './DisplayArticle.css';
import './ArticleSkeletonLoader.css';

function ArticleSkeletonLoader() {
  return (
    <div className="article-detail-page" style={{ height: '100vh' }}>
      <Stack spacing={2}>
        <Skeleton variant="text" width="100%" height={56} />
        <Skeleton variant="rectangular" width="100%" height={300} />
        <Skeleton variant="text" width="60%" height={28} />
        <Skeleton variant="text" width="60%" height={28} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
      </Stack>
    </div>
  );
}

export default ArticleSkeletonLoader;
