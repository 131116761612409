import React, { useEffect } from 'react';
import './Subscriptions.css';

const subscriptions = [
  {
    id: 1,
    name: 'Glyph Pro Plan',
    status: 'Active',
    price: '$9.99/month',
    renewalDate: 'Next renewal: May 15, 2024',
    includes: 'Glyph',
    products: ['Glyph']
  },
  {
    id: 2,
    name: 'Visionary Pro Plan',
    status: 'Active',
    price: '$9.99/month',
    renewalDate: 'Next renewal: June 20, 2024',
    includes: 'Visionary',
    products: ['Visionary']
  },
  {
    id: 3,
    name: 'Audioverse Pro Plan',
    status: 'Active',
    price: '$9.99/month',
    renewalDate: 'Next renewal: July 25, 2024',
    includes: 'Audioverse',
    products: ['Audioverse']
  }
];

const Subscriptions = () => {
  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflowY = 'hidden';

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <section className="subscriptions-section">
      {subscriptions.map((subscription) => (
        <div key={subscription.id} className="form-box">
          <div className="subscription-card">
            <h3>{subscription.name}</h3>
            <div className="card-details">
              <span className="product-name">{subscription.includes}</span>
              <span className="subscription-price">{subscription.price}</span>
              <span className="subscription-renewal">{subscription.renewalDate}</span>
            </div>
            <h3>Status: {subscription.status}</h3>
            <div className="card-actions">
              <button className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Subscriptions;