import React from 'react';
import ImageModelDetail from './ModelDetail/ImageModelDetail';
import { useParams } from 'react-router-dom';

function ImageModelDetailPage() {
    const { ImageGen_id } = useParams();

    return (
        <div>
            <ImageModelDetail ImageGen_id={ImageGen_id} />
        </div>
    );
}

export default ImageModelDetailPage;
