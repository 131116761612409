import React, { useEffect } from 'react';
import Footer from './Footer/Footer';
import GlyphReview from './ProductsReviewPage/GlyphReview';

const GlyphReivewPage = () => {
    // Scroll to top on component mount
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    
  return (
    <div>
      <GlyphReview />
      <Footer />
    </div>
  );
}

export default GlyphReivewPage;