import React, { useState, useEffect, useCallback } from 'react';
import './Gallery.css';
import ModelCardSkeleton from './GallerySkeleton';

function ModelCard({ model, isLoading }) {
  const handleDownloadClick = () => {
    window.open(model.model_page_link, '_blank', 'noopener,noreferrer');
  };

  const handleMoreInfoClickImage = () => {
    window.location.href = `/image-models-details/${model.ImageGen_id}`;
  };

  const formattedModelSize = model.model_size_gb != null ? `${model.model_size_gb} GB` : 'N/A';

  const renderStarRating = (rating) => {
    const totalStars = 5;
    let stars = [];
    for (let i = 1; i <= totalStars; i++) {
        stars.push(
            <span key={i} className={i <= rating ? "filled-star" : "unfilled-star"}>
                {i <= rating ? '★' : '☆'}
            </span>
        );
    }
    return stars;
  };

  return (
    <div className="model-card">
      <h2>{model.model_name}</h2>
      <h3>Author: {model.author_name}</h3>
      <h3>Rating: <span className="rating-stars">{renderStarRating(model.rating)}</span></h3>
      <img src={model.primary_image_url} alt={model.model_name} />
      <div className="model-details">
          <span>Size: {formattedModelSize}</span>
          <span>Likes: {model.likes}</span>
          <span>Dislikes: {model.dislikes}</span>
          <span>Downloads: {model.num_downloads}</span>
      </div>
      <div className="button-container">
        <button onClick={handleMoreInfoClickImage} className="download-button">More Info</button>
        <button onClick={handleDownloadClick} className="MoreInfo-button">View Citivai</button>
      </div>
    </div>
  );
}

function ImageGallery({ filters }) {
  const [models, setModels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 12;
  const indexOfFirstItem = (currentPage) * itemsPerPage;
  const indexOfLastItem = indexOfFirstItem + itemsPerPage;
  const currentModels = models && models.length ? models.slice(indexOfFirstItem, indexOfLastItem) : [];

  const removeDuplicateModels = (data) => {
    return Array.from(new Map(data.map(model => [model.ImageGen_id, model])).values());
  };

  const fetchModels = useCallback(async () => {
    setIsLoading(true);
    let query = buildQueryString(filters);
    try {
      const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}/api/v1/image-models?page=${currentPage}&per_page=12&${query}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
  
      // Check if response.models is available and is an array
      const modelsFromResponse = data.models || [];
      const uniqueModels = removeDuplicateModels(modelsFromResponse);
  
      setModels(uniqueModels);  
      setTotalPages(data.total_pages);
      console.log(totalPages);
      console.log(modelsFromResponse);
  
    } catch (error) {
      console.error("Error loading models:", error);
      setModels([]);  
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  }, [filters, currentPage]);
            
  useEffect(() => {
    fetchModels();
  }, [fetchModels]); 

  const paginate = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  }, []);
  
  const buildQueryString = (filters) => {
    let queryString = '';
    Object.entries(filters).forEach(([key, value]) => {
      const serializedValue = typeof value === 'object' ? encodeURIComponent(JSON.stringify(value)) : encodeURIComponent(value);
      if (serializedValue) queryString += `${key}=${serializedValue}&`;
    });
  
    return queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;
  };
    
  if (isLoading) {
    return (
      <div className="models">
        <h1>Explore AI Models</h1>
        <div className="model-list">
          {Array.from(new Array(12)).map((_, index) => (
            <ModelCardSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  const renderPaginationControls = () => {
    return <>
      <button onClick={() => paginate(1)} disabled={currentPage === 1}>First</button>
      <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
      {Array.from({ length: totalPages }, (_, i) => i + 1)
        .slice(Math.max(currentPage - 2, 0), Math.min(currentPage + 1, totalPages))
        .map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={currentPage === number ? 'active' : ''}
          >
            {number}
          </button>
        ))}
      <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
      <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>Last</button>
    </>;
  };

  return (
    <div className="models">
    <h1>Explore AI Models</h1>
        <div className="model-list">
        {models.map((model, index) => (
            <ModelCard key={model.ImageGen_id} model={model} isLoading={isLoading} />
        ))}
        </div>
      {!isLoading && (
        <div className="pagination">
          <button onClick={() => paginate(1)} disabled={currentPage === 1}>First</button>
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).slice(Math.max(currentPage - 2, 0), Math.min(currentPage + 1, totalPages)).map(number => (
            <button key={number} onClick={() => paginate(number)} className={currentPage === number ? 'active' : ''}>
              {number}
            </button>
          ))}
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
          <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>Last</button>
        </div>
      )}
    </div>
  );
  }
  
  export default ImageGallery;