import React, { useState, useEffect } from 'react';
import Footer from './Footer/Footer';
import DisplayArticle from './DisplayArticlePage/DisplayArticle';
import SkeletonLoader from './DisplayArticlePage/ArticleSkeletonLoader';
import { useParams } from 'react-router-dom';

function DisplayArticlePage() {
    const { Article_ID } = useParams();
    const [footerVisible, setFooterVisible] = useState(false);

    // Simulate article loading completion
    useEffect(() => {
        const articleLoadSimulation = setTimeout(() => setFooterVisible(true), 1500); // Simulate Article loading and show footer after 1 second
        return () => clearTimeout(articleLoadSimulation);
    }, [Article_ID]);

    // Scroll to top on component mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            {/* <SkeletonLoader /> */}
            <DisplayArticle Article_ID={Article_ID} />
            {footerVisible && <Footer />}
        </div>
    );
}

export default DisplayArticlePage;
