import React, { useEffect } from 'react';
import './privacy.css';

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Privacy">
      <div className="Privacy-container">
      <h1 className="Privacy-title">Privacy Policy</h1>
        <p>Last updated: [Date]</p>
        <p>At Fortaify, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to protect your personal information.</p>
        
        <h2>Information Collection and Use</h2>
        <p>We collect information that you provide directly to us when you register for an account, use our services, or communicate with us. This may include your name, email address, and any other information you choose to provide. We use this information to operate, maintain, and provide to you the features and functionality of our services, as well as to communicate directly with you.</p>
        
        <h2>Log Data and Cookies</h2>
        <p>When you access our service, our servers automatically record information that your browser sends ("Log Data"). This Log Data may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics. We also use cookies and similar technologies to collect data about your use of Fortaify.</p>
        
        <h2>Sharing and Disclosure</h2>
        <p>Your privacy is important to us. We do not share personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights and property. We may share non-personally identifiable information publicly and with our partners.</p>
        
        <h2>Your Rights</h2>
        <p>You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us directly. We are committed to facilitating the exercise of these rights in a timely manner.</p>
        
        <h2>Data Security</h2>
        <p>We take the security of your personal information seriously and implement a variety of security measures to maintain the safety of your personal data. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
        
        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
        
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us.</p>
      </div>
    </div>
  );
}

export default Privacy;
