import React, { useEffect, useState } from 'react';
import './Sidebar.css';

function Sidebar({ onFilterChange, onOptionSelect }) {
    const [isOpen, setIsOpen] = useState(false);
    const [modelSize, setModelSize] = useState({ min: '', max: '' });
    const [parameters, setParameters] = useState({ min: '', max: '' });
    const [selectedOption, setSelectedOption] = useState('Text');
    const [sortOptions, setSortOptions] = useState({
        Likes: false,
        Rating: false,
        Downloads: false,
    });
    const [nsfw, setNsfw] = useState(false);
    const [searchModel, setSearchModel] = useState('');
    const [searchAuthor, setSearchAuthor] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    

    useEffect(() => {
        const filters = {
            modelSize,
            parameters,
            nsfw,
            sortOptions,
            searchModel,
            searchAuthor,
        };
        if (onFilterChange) {
            onFilterChange(filters);
        }
    }, [modelSize, parameters, nsfw, sortOptions, searchModel, searchAuthor]);

    const handleInputChange = (name, value, type) => {
        if (name === 'modelSize') {
            setModelSize({ ...modelSize, [type]: value });
        } else if (name === 'parameters') {
            setParameters({ ...parameters, [type]: value });
        }
    };

    const handleResetFilters = () => {
        setModelSize({ min: '', max: '' });
        setParameters({ min: '', max: '' });
        setSortOptions({
            Likes: false,
            Rating: false,
            Downloads: false,
        });
        setNsfw(false);
        setSearchModel('');
        setSearchAuthor('');
    };

    const toggleNSFW = () => {
        setNsfw(!nsfw);
    };

    const selectOption = (option) => {
        if (['Text', 'Image', 'Audio'].includes(option)) {
            setSelectedOption(option);
            if (onOptionSelect) {
                onOptionSelect(option);
            }
        } else {
            setSortOptions(prev => ({
                Likes: option === 'Likes' ? !prev.Likes : false,
                Rating: option === 'Rating' ? !prev.Rating : false,
                Downloads: option === 'Downloads' ? !prev.Downloads : false,
            }));
        }
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleNsfwToggle = () => {
        if (!nsfw) {
            setShowConfirmModal(true);
        } else {
            setNsfw(false);
        }
    };

    const confirmAge = (confirmation) => {
        if (confirmation) {
            setNsfw(true);
        }
        setShowConfirmModal(false);
    };

    return (
        <>
            <button className="sidebar-toggle" onClick={() => setIsOpen(!isOpen)}>
                <i className="fas fa-bars"></i>
            </button>
            <div className={`sidebar ${!isOpen ? 'active' : ''}`}>
                <div className="sidebar-search">
                    <input
                        placeholder="Search by model..."
                        type="text"
                        value={searchModel}
                        onChange={(e) => setSearchModel(e.target.value)}
                    />
                    <input
                        placeholder="Search by author..."
                        type="text"
                        value={searchAuthor}
                        onChange={(e) => setSearchAuthor(e.target.value)}
                    />
                </div>
                <ul>
                    <li>
                        <button
                            className={selectedOption === 'Text' ? 'selected' : ''}
                            onClick={() => selectOption('Text')}
                        >
                            Text Generation
                        </button>
                    </li>
                    <li>
                        <button
                            className={selectedOption === 'Image' ? 'selected' : ''}
                            onClick={() => selectOption('Image')}
                        >
                            Image Generation
                        </button>
                    </li>
                    <li>
                        <button
                            className={selectedOption === 'Audio' ? 'selected' : ''}
                            onClick={() => selectOption('Audio')}
                        >
                            Audio Generation
                        </button>
                    </li>
                </ul>
                <div className="sidebar-info">
                    <div className="slider-container">
                        <div className="slider-content">
                            <label>Model Size (GB)</label>
                            <input
                                type="number"
                                placeholder="Min"
                                value={modelSize.min}
                                onChange={(e) => handleInputChange('modelSize', e.target.value, 'min')}
                            />
                            <input
                                type="number"
                                placeholder="Max"
                                value={modelSize.max}
                                onChange={(e) => handleInputChange('modelSize', e.target.value, 'max')}
                            />
                        </div>
                    </div>
                    <div className="slider-container">
                        <div className="slider-content">
                            <label>Parameters (B)</label>
                            <input
                                type="number"
                                placeholder="Min"
                                value={parameters.min}
                                onChange={(e) => handleInputChange('parameters', e.target.value, 'min')}
                            />
                            <input
                                type="number"
                                placeholder="Max"
                                value={parameters.max}
                                onChange={(e) => handleInputChange('parameters', e.target.value, 'max')}
                            />
                        </div>
                    </div>
                    <div className="slider-container">
                        <div className="slider-content">
                            <label>View Uncensored Models</label>
                            <input
                                type="checkbox"
                                id="nsfwToggle"
                                className="toggle-checkbox"
                                checked={nsfw}
                                onChange={handleNsfwToggle}
                            />
                            <label htmlFor="nsfwToggle" className="toggle-label">
                                {nsfw ? 'NSFW ON' : 'NSFW OFF'}
                            </label>
                        </div>
                    </div>
                    <div className="slider-container">
                        <label>Sorting</label>
                        <ul>
                            <li>
                                <button
                                    className={sortOptions.Likes ? 'selected' : ''}
                                    onClick={() => selectOption('Likes')}
                                >
                                    Most Liked
                                </button>
                            </li>
                            <li>
                                <button
                                    className={sortOptions.Rating ? 'selected' : ''}
                                    onClick={() => selectOption('Rating')}
                                >
                                    Highest Rated
                                </button>
                            </li>
                            <li>
                                <button
                                    className={sortOptions.Downloads ? 'selected' : ''}
                                    onClick={() => selectOption('Downloads')}
                                >
                                    Most Downloaded
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="slider-container">
                        <div className="slider-content">
                            <label>Reset Filters</label>
                            <button className="reset-btn" onClick={handleResetFilters}>Reset</button>
                        </div>
                    </div>
                </div>
                {showConfirmModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <p>Are you 18 years or older?</p>
                            <button onClick={() => confirmAge(true)}>Confirm</button>
                            <button onClick={() => confirmAge(false)}>Cancel</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Sidebar;
